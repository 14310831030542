import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  reviews : any;
  productReview:any;
  sellerReview:any;
}

const initialState: State = {
  isLoading: true,
  error: false,
  reviews: [],
  productReview:[],
  sellerReview:{
    review:[],
    product:[]
  }
};

export const reviewSlice = createSlice({
  name: 'rating',
  initialState,
  reducers: {
    createReviewStart: (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    createReviewSuccess: (state: State, action) => ({
      ...state,
      isLoading : false,
      reviews : action.payload.reviews
    }),
    createReviewFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    getReviews: () => ({
      ...initialState,
      isLoading: true
    }),
    getReviews1: () => ({
      ...initialState,
      isLoading: true
    }),
    getReviewByProduct: (state:State, action) => ({
      ...initialState,
      isLoading: true
    }),
    getReviewBySellerId: (state:State, action) => ({
      ...state,
      isLoading: true
    }),
    getReviewByProductSuccess: (state:State, action) => ({
      ...initialState,
      isLoading: false,
      productReview: action.payload.productReview
    }),
    getReviewBySellerIdSuccess: (state:State, action) => ({
      ...state,
      isLoading: false,
      sellerReview: action.payload
    })
  }
});

export const {
  createReviewStart,
  createReviewSuccess,
  createReviewFailure,
  getReviews,
  getReviewByProduct,
  getReviewBySellerId,
  getReviewByProductSuccess,
  getReviewBySellerIdSuccess,

} = reviewSlice.actions;
export default reviewSlice.reducer;
