import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  orders : any;
  giftCardOrders:any;
  orderCount: number;
}

const initialState: State = {
  isLoading: true,
  error : false,
  orders : [],
  giftCardOrders:[],
  orderCount: 0
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    createOrderStart: (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    createGiftCardOrderStart: (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    createOrderSuccess: (state: State, action) => ({
      ...state,
      isLoading : false,
      orders : action.payload.orders.orderHistory,
      orderCount: action.payload.orders.orderCount
    }),
    createGiftCardOrderSuccess: (state: State, action) => ({
      ...state,
      isLoading : false,
      giftCardOrders : action.payload,
    }),
    createOrderFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    getOrdersStart: (state:State, action) => ({
      ...initialState,
      isLoading : true
    })
  }
});

export const {
  createOrderStart,
  createOrderSuccess,
  createOrderFailure,
  getOrdersStart
} = orderSlice.actions;
export default orderSlice.reducer;
