import { call, put, takeLatest } from "redux-saga/effects";
import snack from "components/wrapper/snack";
import {
  createASingleProductStart,
  updateSingleProductStart,
  deleteSingleProductStart,
  createASingleProductSuccess,
  updateSingleProductSuccess,
  getAllProductsBasedOnFiltersSuccess,
  getAllProductsBasedOnFiltersStart,
  getActiveSellLikeItemProductListStart,
  getActiveSellLikeItemProductListSuccess,
  getSoldSellItemsStart,
  getSoldSellItemsSuccess,
  getSoldSellItemsError,
} from "redux/product";
import {
  getAllProductBasedOnFilter,
  createProduct,
  updateProduct,
  deleteProduct,
  uploadProductImages,
  getActiveSellItemProductList,
  getSoldItemsProductList,
} from "redux/services/product";

import { history } from "routes";
import { checkIsValidArray } from "utils";

function* allProductBasedOnFiltersWatcher() {
  yield takeLatest(
    [
      getAllProductsBasedOnFiltersStart.type,
      createASingleProductStart.type,
      getActiveSellLikeItemProductListStart.type,
      updateSingleProductStart.type,
      deleteSingleProductStart.type,
      getSoldSellItemsStart.type,
    ],
    allProductBasedOnFilters
  );
}

function* allProductBasedOnFilters(action: any): any {
  try {
    switch (action.type) {
      case getAllProductsBasedOnFiltersStart.type:
        {
          const response: any = yield call(
            getAllProductBasedOnFilter,
            action.payload
          );
          yield put(
            getAllProductsBasedOnFiltersSuccess({ allProducts: response })
          );
        }
        break;
      case createASingleProductStart.type:
        {
          const responseFromImages: any = yield call(
            uploadProductImages,
            action.payload.productImages
          );
          let finalProductImages: Array<String> = [];
          if (
            checkIsValidArray(action.payload.productImages) &&
            action.payload.productImages[0]?.data_url &&
            !action.payload.productImages[0]?.file
          ) {
            finalProductImages.concat([
              action.payload.productImages[0]?.data_url,
            ]);
          }
          const productImages = yield finalProductImages.concat(
            responseFromImages
          );
          const response: any = yield call(createProduct, {
            ...action.payload,
            productImages,
          });
          if (response.productUploadStatus) {
            yield put(createASingleProductSuccess({}));
            history.push({
              pathname: "/my-sales",
            });
            snack.success(response.productUploadStatus);
          }
        }
        break;

      case updateSingleProductStart.type:
        {
          const responseFromImages: any = yield call(
            uploadProductImages,
            action.payload.productImages.filter(({ file }: any) => file)
          );
          const finalProductImages: Array<String> = action.payload.productImages
            .map(({ file, data_url }: any) => {
              if (!file && data_url) {
                return data_url;
              }
            })
            .filter((item: any) => item);
          let productImages;
          if (checkIsValidArray(responseFromImages)) {
            productImages = yield finalProductImages.concat(responseFromImages);
          } else {
            productImages = yield finalProductImages;
          }

          const response: any = yield call(updateProduct, {
            ...action.payload,
            productImages,
          });
          if (response.productUploadStatus) {
            yield put(updateSingleProductSuccess({}));
            yield put(createASingleProductSuccess({}));
            snack.success(response.productUploadStatus);
          }
          history.push({
            pathname: "/my-sales",
          });
        }
        break;
      case deleteSingleProductStart.type:
        {
          const response: any = yield call(deleteProduct, {
            ...action.payload,
          });
          if (response.productUploadStatus) {
            history.push({
              pathname: "/",
            });
            snack.success(response.productUploadStatus);
          }
        }
        break;

      case getActiveSellLikeItemProductListStart.type:
        {
          const response: any = yield call(
            getActiveSellItemProductList,
            action.payload
          );
          if (response?.sellItemMessage) {
            yield put(getActiveSellLikeItemProductListSuccess(response));
          } else {
            yield put(
              getActiveSellLikeItemProductListSuccess({
                productDetails: response || {},
              })
            );
          }
        }
        break;
      case getSoldSellItemsStart.type:
        {
          const response: any = yield call(
            getSoldItemsProductList,
            action.payload
          );
          if (checkIsValidArray(response?.orderHistory)) {
            const finalSaleOrders = response?.orderHistory.reduce(
              (acc: any, curr: any) => {
                if (checkIsValidArray(curr?.Items)) {
                  return acc.concat(curr.Items);
                }
                return acc;
              },
              []
            );
            yield put(
              getSoldSellItemsSuccess({
                orderHistory: finalSaleOrders,
              })
            );
          } else {
            yield put(
              getSoldSellItemsError({
                orderHistory: response?.orderHistory,
              })
            );
          }
        }
        break;

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
  }
}

export default allProductBasedOnFiltersWatcher;

