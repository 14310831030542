import axiosInstance from './axiosController';

export const followSeller = async (userId: string, sellerId: string) => {
  if (!userId || !sellerId) {
    throw new Error('Invalid userId or sellerId');
  }

  // Ensure the IDs are valid ObjectId strings
  if (!isValidObjectId(userId) || !isValidObjectId(sellerId)) {
    throw new Error('Invalid userId or sellerId format');
  }

  console.log(`Following seller. UserId: ${userId}, SellerId: ${sellerId}`);

  try {
    const response = await axiosInstance.post('/follow/follow', { userId, sellerId });
    return response.data;
  } catch (error) {
    console.error('Error in followSeller:', error.response?.data || error.message);
    throw error;
  }
};

export const unfollowSeller = async (userId: string, sellerId: string) => {
  if (!userId || !sellerId) {
    throw new Error('Invalid userId or sellerId');
  }

  // Ensure the IDs are valid ObjectId strings
  if (!isValidObjectId(userId) || !isValidObjectId(sellerId)) {
    throw new Error('Invalid userId or sellerId format');
  }

  console.log(`Unfollowing seller. UserId: ${userId}, SellerId: ${sellerId}`);

  try {
    const response = await axiosInstance.post('/follow/unfollow', { userId, sellerId });
    return response.data;
  } catch (error) {
    console.error('Error in unfollowSeller:', error.response?.data || error.message);
    throw error;
  }
};

// Helper function to check if a string is a valid ObjectId
function isValidObjectId(id: string): boolean {
  return /^[0-9a-fA-F]{24}$/.test(id);
}

export const getFollowingStatus = async (userId: string) => {
    if (!userId) {
      throw new Error('Invalid userId');
    }
  
    try {
      console.log('Fetching following status for userId:', userId);
      const response = await axiosInstance.get(`/follow/following/${userId}`);
      console.log('Received following status:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in getFollowingStatus:', error.response?.data || error.message);
      throw error;
    }
  };

