import axios from "api/axiosController";

export const createReview = async (data:any) => {
  let uri = `/product/review`;

    try {
      const res = await axios.post(uri, data, { headers: {"Authorization" : `Bearer ${localStorage.getItem('access-token')}`} });
      return res.data;
    } catch (e) {
      throw e;
    }
};

export const getAllReview = async (userId:string) => {
  let uri = `/product/reviews`;

    try {
      const res = await axios.get(uri, { headers: {"Authorization" : `Bearer ${localStorage.getItem('access-token')}`} });
      return res.data;
    } catch (e) {
      throw e;
    }
};

export const getAllReviewByProduct = async (productId:string) => {
  let uri = `/product/reviewsByProduct`;
    try {
      const res = await axios.post(uri, {productId:productId});
      return res.data;
    } catch (e) {
      throw e;
    }
};

export const getAllReviewBySellerId = async (sellerId:string) => {
  let uri = `/product/reviewsBySellerId`;
    try {
      const res = await axios.post(uri, {sellerId:sellerId});
      return res.data;
    } catch (e) {
      throw e;
    }
};
