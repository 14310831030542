import { call, put, takeLatest } from "redux-saga/effects";
import {
  getelectronicsProductsStart,
  getelectronicsProductsSuccess,
  getelectronicsProductsFailure

} from 'redux/Home/electronics';
import { getElectronicsProducts } from "redux/services/Home/electronics";

function* electronicsSagaWatcher() {
  yield takeLatest(
    [
      getelectronicsProductsStart.type
    ],
    electronicsWorker,
  );
}

function* electronicsWorker(action: any): any {
  try {
    switch (action.type) {
      case getelectronicsProductsStart.type: {
        const response: any = yield call(getElectronicsProducts, action.payload.category);
        yield put(getelectronicsProductsSuccess({
          category: response.ResponseBody.category,
          result: response.ResponseBody.result,
        }));
      }
        break;

      default: console.error('invalid case');
        break;
    }

  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getelectronicsProductsStart.type) {
      yield put(getelectronicsProductsFailure(action.payload.category));
    } else if (action.type === getelectronicsProductsStart.type) {
      console.log('error in getting electronics products');
    }
  }
}

export default electronicsSagaWatcher;
