import axios from "./axiosController";

// This api fetches the top categories and top brands data for the navigation bar
const getDropdownItems = async () => {
  let url = "categories/dashboard";

  try {
    const res = await axios.get(url);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export default getDropdownItems;
