import { call, put, takeLatest } from "redux-saga/effects";
import {
  initiatePaypalPaymentFailure,
  initiatePaypalPaymentStart,
  initiatePaypalPaymentSuccess,
} from "redux/paypal";
import { createOrderIntent } from "redux/services/payment";

function* paypalPaymentSagaWatcher() {
  yield takeLatest([initiatePaypalPaymentStart.type], paymentSagaWorker);
}

function* paymentSagaWorker(action: any): any {
  try {
    switch (action.type) {
      case initiatePaypalPaymentStart.type:
        const data = yield call(createOrderIntent, action.payload);
        yield put(
          initiatePaypalPaymentSuccess({
            secretKey: data.ResponseBody.clientSecret,
          })
        );
        break;
      default:
        break;
    }
  } catch (err) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === initiatePaypalPaymentStart.type) {
      yield put(initiatePaypalPaymentFailure(err));
    }
  }
}

export default paypalPaymentSagaWatcher;
