import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface ShopBannerProps {
  seller: any;
  userId: string;
}


const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

export default function ShopBanner({ seller, userId }: ShopBannerProps): JSX.Element {
  const [error, setError] = useState('');



  const renderContent = () => {
    if (!seller || !seller.sellerId) {
      return <div>No seller information available</div>;
    }

    const sellerId = seller.sellerId;
    const Seller_Shop_Name = seller?.shopName || seller?.firstName || "";

    if (sellerId === "29658816") {
      return (
        <div className="shopBannerImage">
          <img
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "400px",
              objectFit: "fill",
            }}
            src="https://storage.googleapis.com/content.paysfer.com/banner/anyco_banner.webp"
            alt="Seller Image"
          />
        </div>
      );
    } 
    else if (sellerId === "0592de2a-815c-4929-a521-82301b246cce") {
      return (
        <div className="shopBannerImage">
          <img
            className='shopBannerImageBookShop'
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "400px",
              objectFit: "fill",
            }}
            src="https://storage.googleapis.com/content.paysfer.com/banner/bookshop-banner.jpg"
            alt="Seller Image"
          />
        </div>
      )
    }
    else if (sellerId === "40551232") {
      return (
        <div className="shopBannerImage">
          <img
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "350px",
              objectFit: "fill",
            }}
            src="https://storage.googleapis.com/content.paysfer.com/banner/updated_banners/RJ_BULLION_BANNER.jpg"
            alt="Seller Image"
          />
        </div>
      );
    } else if (sellerId === "81418180") {
      return (
        <div className="shopBannerImage">
          <img
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "400px",
              objectFit: "fill",
            }}
            src="https://storage.googleapis.com/content.paysfer.com/banner/Kalustyans_Banner.webp"
            alt="Seller Image"
          />
        </div>
      );
    } else {
      return (
        <div className="shopDetailsHeader">
          <h3>{Seller_Shop_Name.toUpperCase()}</h3>
        </div>
      );
    }
  };

  return (
    <div className="shopBannerContainer">
      {renderContent()}
      
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}
