import { call, put, takeLatest } from "redux-saga/effects";
import {
  getgamesProductsStart,
  getgamesProductsSuccess,
  getgamesProductsFailure

} from 'redux/Home/games';
import { getGamesProducts } from "redux/services/Home/games";

function* gamesSagaWatcher() {
  yield takeLatest(
    [
      getgamesProductsStart.type
    ],
    gamesWorker,
  );
}

function* gamesWorker(action: any): any {
  try {
    switch (action.type) {
      case getgamesProductsStart.type: {
        const response: any = yield call(getGamesProducts, action.payload.category);
        yield put(getgamesProductsSuccess({
          category: response.ResponseBody.category,
          result: response.ResponseBody.result,
        }));
      }
        break;

      default: console.error('invalid case');
        break;
    }

  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getgamesProductsStart.type) {
      yield put(getgamesProductsFailure(action.payload.category));
    } else if (action.type === getgamesProductsStart.type) {
      console.log('error in getting games products');
    }
  }
}

export default gamesSagaWatcher;
