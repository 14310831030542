import { Router }                     from "react-router-dom";
import CustomRoutes                   from './customRoutes';
import { createBrowserHistory }       from 'history';
import { useEffect } from "react";
import CanonicalComponent from "components/Canonical/CanonicalComponent";

const history = createBrowserHistory ();

const Routes = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Router history={history}>
      <CanonicalComponent />
      <CustomRoutes/>
    </Router>
  );
}

export { Routes, history }
