import { call, put, takeLatest } from "redux-saga/effects";
import {
  getshoesProductsStart,
  getshoesProductsSuccess,
  getshoesProductsFailure

} from 'redux/Home/shoes';
import { getshoesProducts } from "redux/services/Home/shoes";

function* shoesSagaWatcher() {
  yield takeLatest(
    [
      getshoesProductsStart.type
    ],
    shoesWorker,
  );
}

function* shoesWorker(action: any): any {
  try {
    switch (action.type) {
      case getshoesProductsStart.type: {
        const response: any = yield call(getshoesProducts, action.payload.category);
        yield put(getshoesProductsSuccess({
          category: response.ResponseBody.category,
          result: response.ResponseBody.result,
        }));
      }
        break;

      default: console.error('invalid case');
        break;
    }

  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getshoesProductsStart.type) {
      yield put(getshoesProductsFailure(action.payload.category));
    } else if (action.type === getshoesProductsStart.type) {
      console.log('error in getting shoes products');
    }
  }
}

export default shoesSagaWatcher;
