// @ts-nocheck
import React from "react";
import { List, ListItem, ListItemText, Divider, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useQuery, useSearch, useSearchContext } from "@sajari/react-hooks";

const Menu = (props: any) => {
  const history = useHistory();
  const { categoryName, categoryId, childData = [] } = props;
  const isExpandable = childData && childData.length > 0;
  const [open, setOpen] = React.useState(false);
  const { query, setQuery } = useQuery();
  const { results = [] } = useSearch();
  const { searched } = useSearchContext();

  function handleClick() {
    setOpen(!open);
  }

  const handleChangeCategory = (category: any) => {
    setQuery(category.categoryName);
    history.push({
      pathname: "/search-results",
      search: `?${"q"}=${category.categoryName}`,
      state: {
        searched: searched,
        results: results,
      },
    });

    props.setOpenHamburger(false);
    props.handleCloseHamburger();
  };

  const MenuItemRoot = (
    <ListItem
      button
      onClick={
        isExpandable
          ? handleClick
          : () => handleChangeCategory({ categoryName, categoryId })
      }
    >
      <ListItemText primary={categoryName} />
      {isExpandable && !open && <ExpandMore />}
      {isExpandable && open && <ExpandLess />}
    </ListItem>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {childData.map((item: any, index: number) => (
          <ListItem {...item} key={index}>
            <ListItemText
              style={{ cursor: "pointer" }}
              primary={item.categoryName}
              onClick={() => handleChangeCategory(item)}
            />
          </ListItem>
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

export default Menu;
