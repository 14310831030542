import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  stationary?: any;
  error?: boolean;
  msg?: string
}

const initialState: State = {
  isLoading: false,
  stationary: [],
  error: false,
  msg: ''
};

export const stationarySlice = createSlice({
  name: 'homepage-section-stationary',
  initialState,
  reducers: {
    getstationaryProductsStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    getstationaryProductsSuccess: (state: State, action) => ({
      ...state,
      stationary: action.payload.result
    }),
    getstationaryProductsFailure: (state: State, action) => ({
      ...state,
      error: action.payload.error,
      msg: action.payload.msg
    })
  }
});

export const {
  getstationaryProductsStart,
  getstationaryProductsSuccess,
  getstationaryProductsFailure
} = stationarySlice.actions;
export default stationarySlice.reducer;
