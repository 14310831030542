// CanonicalComponent.js
import { useLocation } from 'react-router-dom';

function CanonicalComponent() {
    const location = useLocation();
    const canonicalUrl = window.location.origin + location.pathname;

    return <link rel="canonical" href={canonicalUrl} />;
}

export default CanonicalComponent;
