import { _axiosInstance } from "api/axiosController";
export const getSellers = async () => {
  let uri = `seller/v1/sellers`;

  try {
    const res = await _axiosInstance.get(uri);
    return res.data;
  } catch (e) {
    throw e;
  }
};
export const getUsersAsSellers = async () => {
  let uri = `seller/v1/usersAsSellers`;

  try {
    const res = await _axiosInstance.get(uri);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getSellerById = async (sellerid: String) => {
  let uri = `seller/v1/seller/${sellerid}`;
  try {
    const res = await _axiosInstance.get(uri);
    return res.data?.ResponseBody;
  } catch (e) {
    throw e;
  }
};
export const updateShopDetails = async (data: object) => {
  let uri = "seller/v1/updateShopDetails";
  try {
    const res = await _axiosInstance.post(uri, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access-token")}`,
      },
    });
    return res.data?.ResponseBody;
  } catch (e) {
    throw e;
  }
};

export const getAllProduct = async (data: string) => {
  let uri = `seller/v1/seller-pagination-products`;
  try {
    const res = await _axiosInstance.post(uri, data);
    return res.data;
  } catch (e) {
    throw e;
  }
};
