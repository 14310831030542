import { call, put, takeLatest } from "redux-saga/effects";
import {
  getstationaryProductsStart,
  getstationaryProductsSuccess,
  getstationaryProductsFailure

} from 'redux/Home/stationary';
import { getstationaryProducts } from "redux/services/Home/stationary";

function* stationarySagaWatcher() {
  yield takeLatest(
    [
      getstationaryProductsStart.type
    ],
    stationaryWorker,
  );
}

function* stationaryWorker(action: any): any {
  try {
    switch (action.type) {
      case getstationaryProductsStart.type: {
        const response: any = yield call(getstationaryProducts, action.payload.category);
        yield put(getstationaryProductsSuccess({
          category: response.ResponseBody.category,
          result: response.ResponseBody.result,
        }));
      }
        break;

      default: console.error('invalid case');
        break;
    }

  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getstationaryProductsStart.type) {
      yield put(getstationaryProductsFailure(action.payload.category));
    } else if (action.type === getstationaryProductsStart.type) {
      console.log('error in getting stationary products');
    }
  }
}

export default stationarySagaWatcher;
