import { _axiosInstance } from "api/axiosController";
export const getSellerCategories = async (data:string) => {
  let uri = `seller/v1/seller-categories`;
    try {
      const res = await _axiosInstance.post(uri,{sellerId:data});
      return res.data;
    } catch (e) {
      throw e;
    }
};
