import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";

export const geteGiftCardStripeCheckoutUrl = async (data: any) => {
  let uri = "/payment/stripe-gift-card-checkout";
  try {
    console.log("price", data);
    const res = await axios.post(
      uri,
      data,
      { headers: { Authorization: `Bearer ${getAccessToken()}` } }
    );
    return res.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const createGiftCardOrder = async (data: any) => {
  let uri = "/orders/create-gift-card";
  try {
    console.log("create-gift-card", data);
    const res = await axios.post(
      uri,
      { data },
      { headers: { Authorization: `Bearer ${getAccessToken()}` } }
    );
    return res.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getGiftCardOrder = async () => {
  let uri = "/orders/get-gift-cards";
  try {
    console.log("get-gift-card");
    const res = await axios.get(uri, {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    });
    return res.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const sendGiftCardService = async (data: any) => {
  let uri = "/orders/send-gift-card";
  try {
    console.log("send-gift-card", data);
    const res = await axios.post(
      uri,
      { data },
      { headers: { Authorization: `Bearer ${getAccessToken()}` } }
    );
    return res.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
