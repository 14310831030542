import { Routes } from "./routes";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import algoliasearch from "algoliasearch";
import { InstantSearch, Configure } from "react-instantsearch-hooks-web";
import { gapi } from "gapi-script";

import "./styles/common.css";
import { useEffect } from "react";
import aa from "search-insights";
// import { Autocomplete } from "autocomplete";

export const searchClient = algoliasearch(
  "ZYWA1Y4MN0",
  "156d9abfa026b631f0ed89adc2833292"
);

// for accessing virtual replicas used to sort prices
export const searchClient_virtual = algoliasearch(
  "ZYWA1Y4MN0",
  "6948e684e6d1b68ea134033d6cb34074"
);

export const searchClientIndex = searchClient.initIndex("paysfer");

export const searchClientIndexSuggestions = searchClient.initIndex(
  "paysfer_query_suggestions"
);

export const searchClientIndex_virtual =
  searchClient_virtual.initIndex("paysfer");

export const paysfer_price_asc_virtual = searchClient_virtual.initIndex(
  "paysfer_price_asc_virtual"
);

export const paysfer_price_desc_virtual = searchClient_virtual.initIndex(
  "paysfer_price_desc_virtual"
);

// Define settings for virtual replicas
const ascSettings = {
  customRanking: ["asc(price0)"],
};

const descSettings = {
  customRanking: ["desc(price0)"],
};

paysfer_price_asc_virtual
  .setSettings(ascSettings)
  .then(() => {
    console.log("Settings added to paysfer_price_asc_virtual");
  })
  .catch((error) => {
    console.error(
      "Error setting settings for paysfer_price_asc_virtual:",
      error
    );
  });

paysfer_price_desc_virtual
  .setSettings(descSettings)
  .then(() => {
    console.log("Settings added to paysfer_price_desc_virtual");
  })
  .catch((error) => {
    console.error(
      "Error setting settings for paysfer_price_desc_virtual:",
      error
    );
  });

const App = () => {
  useEffect(() => {
    // Initialize Algolia Insights
    aa("init", {
      appId: "ZYWA1Y4MN0",
      apiKey: "156d9abfa026b631f0ed89adc2833292",
    });
    // Initialize Google API client
    function start() {
      gapi.client.init({
        clientId:
          "337705274204-gac97kappr7imkhg32ijarfdgv0jfp8o.apps.googleusercontent.com",
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  return (
    <Provider store={store}>
      <InstantSearch
        searchClient={searchClient}
        indexName="paysfer"
        insights={false}
      >
        <Configure clickAnalytics={true} />
        <Routes />
      </InstantSearch>
    </Provider>
  );
};

export default App;
