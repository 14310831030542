import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  valentine?: any;
  error?: boolean;
  msg?: string
}

const initialState: State = {
  isLoading: false,
  valentine: [],
  error: false,
  msg: ''
};

export const valentineSlice = createSlice({
  name: 'homepage-section-valentine',
  initialState,
  reducers: {
    getvalentineProductsStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    getvalentineProductsSuccess: (state: State, action) => ({
      ...state,
      valentine: action.payload.result
    }),
    getvalentineProductsFailure: (state: State, action) => ({
      ...state,
      error: action.payload.error,
      msg: action.payload.msg
    })
  }
});

export const {
  getvalentineProductsStart,
  getvalentineProductsSuccess,
  getvalentineProductsFailure
} = valentineSlice.actions;
export default valentineSlice.reducer;
