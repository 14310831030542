import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  checkoutUrl:string;
  paymentIntentId:string;
  giftCardOrders:any;
 
}

const initialState: State = {
  isLoading: true,
  error : false,
  checkoutUrl:"",
  paymentIntentId:"",
  giftCardOrders:[]
};

export const eGiftCardSlice = createSlice({
  name: 'eGiftCard',
  initialState,
  reducers: {
    createEgiftCardStart: (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    createGiftCardOrderStart: (state: State, action) => ({
      ...state,
      error: false,
      isLoading: false,
    }),
    getGiftCardOrderStart: (state: State) => ({
      ...initialState,
      isLoading: true
    }),
    getGiftCardOrderSuccess: (state: State, action) => ({
      ...state,
      isLoading : false,
      giftCardOrders : action.payload,
    }),
    createEgiftCardSuccess: (state: State, action) => ({
      ...state,
      isLoading : false,
      checkoutUrl: action.payload.url,
      paymentIntentId: action.payload.paymentIntentId,
    }),
    createEgiftCardFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    sendEgiftCardStart: (state: State,action:any) => ({
      ...state,
      error: false,
      isLoading: false,
    }),
  }
});

export const {
    createEgiftCardStart,
    createEgiftCardSuccess,
    createEgiftCardFailure,
    createGiftCardOrderStart,
    getGiftCardOrderStart,
    getGiftCardOrderSuccess,
    sendEgiftCardStart
} = eGiftCardSlice.actions;
export default eGiftCardSlice.reducer;
