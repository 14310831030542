import { createSlice } from "@reduxjs/toolkit";

interface State {
  secretKey: string;
  paymentMethods: Array<Object>;
  checkoutUrl: string;
  error: boolean;
  isLoading: boolean;
  paymentIntentId: string;
}

const initialState: State = {
  secretKey: "",
  paymentMethods: [],
  paymentIntentId: "",
  checkoutUrl: "",
  error: false,
  isLoading: false,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    updatePaymentMethodsStart: (state: State, action) => ({
      ...initialState,
      error: false,
      isLoading: true,
    }),
    updatePaymentMethodsSuccess: (state: State, action) => ({
      ...state,
      paymentMethods: action.payload.paymentMethods,
      isLoading: false,
    }),
    getSecretKeyStart: (state: State, action) => ({
      ...initialState,
      error: false,
      isLoading: true,
    }),
    getSecretKeySuccess: (state: State, action) => ({
      ...state,
      secretKey: action.payload.secretKey,
      paymentMethods: action.payload.paymentMethods,
      isLoading: false,
    }),
    getCheckoutUrlStart: (state: State, action) => ({
      ...initialState,
      error: false,
      isLoading: true,
    }),
    getCheckoutUrlSuccess: (state: State, action) => ({
      ...state,
      checkoutUrl: action.payload.url,
      paymentIntentId: action.payload.paymentIntentId,
      isLoading: false,
    }),
    getSecretKeyFailure: (state: State, action) => ({
      ...state,
      error: action.payload,
      isLoading: false,
    }),
    updateSecretKeyStart: (state: State, action) => ({
      ...state,
      error: false,
      isLoading: false,
    }),
    updateSecretKeySuccess: (state: State, action) => ({
      ...state,
      secretKey: action.payload.secretKey,
      isLoading: false,
    }),
  },
});

export const {
  getSecretKeyStart,
  getSecretKeySuccess,
  getSecretKeyFailure,
  updateSecretKeyStart,
  updateSecretKeySuccess,
  updatePaymentMethodsStart,
  updatePaymentMethodsSuccess,
  getCheckoutUrlStart,
  getCheckoutUrlSuccess,

} = paymentSlice.actions;
export default paymentSlice.reducer;
