import {_axiosInstance} from "api/axiosController";

export const getValentineProducts = async (query: number) => {
  let uri = `/search/v1/get?query=valentine`;
  try {
    const res:any = await _axiosInstance.get(uri);
    console.log ({valentine : res })
    return res.data;
  } catch (e) {
    throw e;
  }
};

