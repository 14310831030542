import { call, put, takeLatest } from "redux-saga/effects";
import {
  getbagsProductsStart,
  getbagsProductsSuccess,
  getbagsProductsFailure

} from 'redux/Home/bags';
import { getBagsProducts } from "redux/services/Home/bags";

function* bagsSagaWatcher() {
  yield takeLatest(
    [
      getbagsProductsStart.type
    ],
    bagsWorker,
  );
}

function* bagsWorker(action: any): any {
  try {
    switch (action.type) {
      case getbagsProductsStart.type: {
        const response: any = yield call(getBagsProducts, action.payload.category);
        yield put(getbagsProductsSuccess({
          category: response.ResponseBody.category,
          result: response.ResponseBody.result,
        }));
      }
        break;

      default: console.error('invalid case');
        break;
    }

  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getbagsProductsStart.type) {
      yield put(getbagsProductsFailure(action.payload.category));
    } else if (action.type === getbagsProductsStart.type) {
      console.log('error in getting bags products');
    }
  }
}

export default bagsSagaWatcher;
