import { call, put, takeLatest } from "redux-saga/effects";
import {
  getDeliveryChargeStart,
  getDeliveryChargeSuccess,
  getDeliveryChargeError,
} from "redux/deliveryitem";

import { getDeliveryDetailsService } from "redux/services/deliveryitem";

function* deliveryDetailsSagaWorker(action: any): any {
  try {
    switch (action.type) {
      case getDeliveryChargeStart.type:
        {
          const response: any = yield call(
            getDeliveryDetailsService,
            action.payload.id
          );
          if (!(response?.Errors.length > 0)) {
            yield put(
              getDeliveryChargeSuccess({
                deliveryDetails: response?.ResponseBody,
              })
            );
          } else {
            yield put(getDeliveryChargeError({}));
          }
        }
        break;
      default:
        break;
    }
  } catch (err: any) {
    yield put(getDeliveryChargeError({}));
  }
}

function* deliveryDetailsSagaWatcher() {
  yield takeLatest([getDeliveryChargeStart.type], deliveryDetailsSagaWorker);
}

export default deliveryDetailsSagaWatcher;
