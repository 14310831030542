import { createSlice } from "@reduxjs/toolkit";

interface State {
  sellers: Array<Object>;
  seller: any;
  sellerFilters: any;
  products: any;
  error: boolean;
  isLoading: boolean;
}

const initialState: State = {
  sellers: [],
  seller: {
    _id: "",
    email: "",
    firstName: "",
    lastName: "",
    sellerId: "",
    shopName: "",
  },
  sellerFilters: { categories: [], brand: [] },
  products: {
    parentCategoryDetails: [],
    products: [],
    page: 1,
    totalCount: 0,
    totalPages: 0,
  },
  error: false,
  isLoading: false,
};

export const shopsSlice = createSlice({
  name: "shops",
  initialState,
  reducers: {
    getSellersStart: (state: State) => ({
      ...state,
      error: false,
      isLoading: true,
    }),
    getSellersSuccess: (state: State, action) => ({
      ...state,
      sellers: action.payload,
      isLoading: false,
    }),
    cancelSellers: (state: State, action) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    getProductsStart: (state: State, action) => ({
      ...initialState,
      error: false,
      isLoading: true,
    }),

    getProductsSuccess: (state: State, action) => {
      return {
        ...initialState,
        seller: action.payload?.response?.sellerDetails,
        products: {
          parentCategoryDetails: action.payload?.parentCategoryDetails,
          products: action.payload?.response?.products,
          page: action.payload?.response?.page,
          totalCount: action.payload?.response?.totalCount,
          totalPages: action.payload?.response?.totalPages,
        },
        isLoading: false,
      };
    },
    updateOnlyProductsStart: (state: State, action) => ({
      ...state,
      error: false,
      isLoading: true,
      products: {},
    }),
    updateOnlyProductsSuccess: (state: State, action) => {
      return {
        ...state,
        products: {
          parentCategoryDetails: action.payload?.parentCategoryDetails,
          products: action.payload?.response?.products,
          page: action.payload?.response?.page,
          totalCount: action.payload?.response?.totalCount,
          totalPages: action.payload?.response?.totalPages,
        },
        isLoading: false,
      };
    },

    cancelProducts: (state: State, action) => ({
      ...initialState,
      error: true,
      isLoading: false,
    }),
    updateShopDetailsStart: (state: State, action) => ({
      ...state,
      shopDetailsAreUpdating: true,
    }),
    updateShopDetailsSuccess: (state: State, action) => ({
      ...state,
      shopDetailsAreUpdating: false,
      seller: {
        ...(state?.seller || {}),
        shopName: action.payload?.shopName,
      },
    }),
    updateShopDetailsError: (state: State, action) => ({
      ...state,
      shopDetailsAreUpdating: false,
    }),
    getSellerDetailsStart: (state: State, action) => ({
      ...state,
      shopDetailsAreUpdating: true,
    }),
    getSellerDetailsSuccess: (state: State, action) => ({
      ...state,
      shopDetailsAreUpdating: false,
      seller: action.payload.seller,
    }),
    getSellerDetailsError: (state: State, action) => ({
      ...state,
      shopDetailsAreUpdating: false,
    }),
  },
});

export const {
  getSellersStart,
  getSellersSuccess,
  cancelSellers,
  getProductsStart,
  getProductsSuccess,
  cancelProducts,
  updateShopDetailsStart,
  updateShopDetailsSuccess,
  updateShopDetailsError,
  getSellerDetailsStart,
  getSellerDetailsSuccess,
  getSellerDetailsError,
  updateOnlyProductsStart,
  updateOnlyProductsSuccess,
} = shopsSlice.actions;

export default shopsSlice.reducer;
