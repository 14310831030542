import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  kitchen?: any;
  error?: boolean;
  msg?: string
}

const initialState: State = {
  isLoading: false,
  kitchen: [],
  error: false,
  msg: ''
};

export const kitchenSlice = createSlice({
  name: 'homepage-section-kitchen',
  initialState,
  reducers: {
    getKitchenProductsStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    getKitchenProductsSuccess: (state: State, action) => ({
      ...state,
      kitchen: action.payload.result
    }),
    getKitchenProductsFailure: (state: State, action) => ({
      ...state,
      error: action.payload.error,
      msg: action.payload.msg
    })
  }
});

export const {
  getKitchenProductsStart,
  getKitchenProductsSuccess,
  getKitchenProductsFailure
} = kitchenSlice.actions;
export default kitchenSlice.reducer;
