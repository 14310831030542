import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";


export const getMySubscriptions = async () => {
  let uri = `/payment/stripe/subscriptions/list`;
  try {
    const res = await axios.get(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } })
    return res.data
  } catch (e) {
    throw e
  }
}

export const cancelSubscription = async (subscriptionId: string) => {
  let uri = `/payment/stripe/subscriptions/delete/${subscriptionId}`;
  try {
    const res = await axios.delete(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } })
    return res.data
  } catch (e) {
    throw e
  }
}

export const updateSubscription = async (data: any) => {
  let uri = `/payment/stripe/subscriptions/update`;
  try {
    const res = await axios.post(uri,data, { headers: { "Authorization": `Bearer ${getAccessToken()}` } })
    return res.data
  } catch (e) {
    throw e
  }
}
