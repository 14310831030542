import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";

export const getDeliveryDetailsService = async (productId: any) => {
  let uri = "product/get-delivery-details";
  try {
    const res = await axios.post(
      uri,
      {
        id: productId,
      },
      {
        headers: { Authorization: `Bearer ${getAccessToken()}` },
      }
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};
