import { call, put, takeLatest } from "redux-saga/effects";
import { getSellerCategoriesStart, getSellerCategoriesSuccess } from "redux/sellerShopFilter";

import { getSellerCategories } from "redux/services/sellersShopFilter";


function* sellersShopFilterSagaWatcher() {
  yield takeLatest(
    [getSellerCategoriesStart.type],
    sellersShopFilterWorker
  );
}

function* sellersShopFilterWorker(action: any): any {
  try {
    switch (action.type) {
        case getSellerCategoriesStart.type:
        {
          const response: any = yield call(getSellerCategories, action.payload);
          yield put(getSellerCategoriesSuccess(response.ResponseBody));
        }
        break;
 
      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
  }
}

export default sellersShopFilterSagaWatcher;
