// @ts-nocheck
import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import SignIn from "components/Layout/SiteContent/Signin";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import useWindowSize from "utils/useWindowSize";
import { updateMobileState } from "redux/mobileLogin";
import "./styles.css";
import { useLocation } from "react-router-dom";
import ROUTE_PATHS from "routes/routesPath";
const MobileViewLogin: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const view: any = useSelector(
    (state: RootState) => state.mobileloginstate.view
  );
  const size = useWindowSize();
  const signInPath = location.pathname;
  useEffect(() => {
    const delayedEffect = setTimeout(() => {
      let accessToken = localStorage.getItem("access-token");
      if (accessToken != null) dispatch(updateMobileState(false));
      else dispatch(updateMobileState(true));
    }, 45000);

    return () => clearTimeout(delayedEffect); // Cleanup the timeout if the component is unmounted
  }, [dispatch]);
  if (signInPath === ROUTE_PATHS.SIGN_IN) {
    return null;
  }
  return (
    <div>
      {size.x < 1000 && (
        <Drawer
          anchor="bottom"
          open={view}
          onClose={() => dispatch(updateMobileState(false))}
        >
          <p
            className="closeButton"
            onClick={() => dispatch(updateMobileState(false))}
          >
            X
          </p>

          <SignIn fromPopper />
        </Drawer>
      )}
    </div>
  );
};

export default MobileViewLogin;
