import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  shoes?: any;
  error?: boolean;
  msg?: string
}

const initialState: State = {
  isLoading: false,
  shoes: [],
  error: false,
  msg: ''
};

export const shoesSlice = createSlice({
  name: 'homepage-section-shoes',
  initialState,
  reducers: {
    getshoesProductsStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    getshoesProductsSuccess: (state: State, action) => ({
      ...state,
      shoes: action.payload.result
    }),
    getshoesProductsFailure: (state: State, action) => ({
      ...state,
      error: action.payload.error,
      msg: action.payload.msg
    })
  }
});

export const {
  getshoesProductsStart,
  getshoesProductsSuccess,
  getshoesProductsFailure
} = shoesSlice.actions;
export default shoesSlice.reducer;
