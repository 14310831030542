import { useAutocomplete } from "@sajari/react-hooks";
import { useSearchBox, useHits } from "react-instantsearch-hooks-web";
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Combobox } from "@sajari/react-components";
import { useHistory } from "react-router-dom";
import { useState, createRef, useCallback } from "react";
import useComponentVisible from "./setVisible";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Fab,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useEffect } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import type { UseSearchBoxProps } from "react-instantsearch-hooks-web";
import { useAlgoliaAutocomplete } from "autocomplete";
import { Configure, Index } from "react-instantsearch-hooks-web";
import { searchClientIndexSuggestions } from "App";
import useWindowSize from "utils/useWindowSize";

const queryHook: UseSearchBoxProps["queryHook"] = (query, search) => {
  // console.log(search, "check queryhook search here!!");
  search(query);
}; // will integrate later
export default function Search() {
  const history = useHistory();
  const { query, refine, clear, isSearchStalled } = useSearchBox({ queryHook });
  const { results } = useHits();
  const [searched] = useState(true);
  const [topCategory, setTopCategory] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const { currentRefinement, refine: AlgoliaRefine } = useAlgoliaAutocomplete();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);
  const topCategories = useSelector(
    (state: RootState) => state.categories.topCategoryList
  );
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const fabItemRef = createRef<HTMLDivElement>();
  const size = useWindowSize();

  useEffect(() => {
    searchClientIndexSuggestions
      .search(currentRefinement, {})
      .then(({ hits }: any) => {
        setSuggestions(hits?.map((data: any) => data.query));
      });
  }, [currentRefinement]);

  const handleSearch = useCallback(
    (value: string) => {
      setSearchClicked(true);
      history.push({
        pathname: "/search-results",
        search: `?q=${value}`,
        state: {
          value,
          searched: searched,
          results: results,
        },
      });
    },
    [history, results, searched]
  );
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter" && query) {
        handleSearch(query);
      }
    },
    [handleSearch, query]
  );

  const handleChangeTopCategory = useCallback(
    (event: SelectChangeEvent) => {
      const value = event.target.value as string;
      setIsSelectOpen(true);
      refine(value);
      setTopCategory(value);
      history.push({
        pathname: "/search-results",
        search: `?q=${value}`,
        state: {
          query: value,
          searched: searched,
          results: results,
        },
      });
    },
    [refine, history, results, searched]
  );

  const handleSelectOpen = useCallback(() => {
    setIsSelectOpen(true);
    document.documentElement.style.overflowY = "hidden";
  }, []);

  const handleSelectClose = useCallback(() => {
    setIsSelectOpen(false);
    document.documentElement.style.overflowY = "unset";
  }, []);

  const selectRenderValue = useCallback(
    (selected: any) => (selected ? null : null),
    []
  );

  const comboboxOnChange = useCallback(
    (value: any) => {
      if (searchClicked) setSearchClicked(false);
      refine(value);
      setIsComponentVisible(true);
    },
    [refine, searchClicked, setIsComponentVisible]
  );
  return (
    <div style={{ position: "absolute", zIndex: 1000 }}>
      {/* <SearchBox /> */}
      {/* <Hits hitComponent={Hit} /> */}
      <Index indexName="paysfer_query_suggestions">
        <Configure hitsPerPage={4} />
        {/* <Autocomplete /> */}
        <Combobox
          size="lg"
          mode="suggestions"
          name="query"
          label="Query"
          value={query}
          onKeyDown={handleKeyDown}
          onChange={comboboxOnChange}
        />
      </Index>
      {size.x > 1000 && (
        <FormControl fullWidth>
          <Select
            value={query ? topCategory : ""}
            onChange={handleChangeTopCategory}
            className="header-select search-dropdown"
            onOpen={handleSelectOpen}
            onClose={handleSelectClose}
            label="All"
            renderValue={selectRenderValue}
          >
            {topCategories &&
              topCategories.map((item: any, index: number) => (
                <MenuItem
                  className="header-menu-item  header-category-menu"
                  key={index}
                  value={item.categoryName}
                >
                  {item.categoryName}
                </MenuItem>
              ))}
            <div ref={fabItemRef} className="bottom-fixed-section-fab-button">
              <Fab
                size="small"
                onClick={() =>
                  fabItemRef.current!.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  })
                }
                color="primary"
                aria-label="add"
              >
                <ArrowDownwardIcon />
              </Fab>
            </div>
          </Select>
        </FormControl>
      )}

      <button
        type="button"
        onClick={() => {
          if (query) {
            handleSearch(query);
          }
        }}
        className="btn btn-primary header-search-btn"
      >
        <i className="fas fa-search"></i>
      </button>

      <div ref={ref}>
        {query &&
          suggestions &&
          suggestions.length > 0 &&
          (!searchClicked && isComponentVisible ? (
            <ul className="list-disc px-5 space-y-2 mt-5">
              {suggestions &&
                suggestions.map((item: any, index: number) => (
                  <li
                    key={item}
                    onClick={() => {
                      refine(item);
                      handleSearch(item);
                    }}
                  >
                    <p>{item}</p>
                  </li>
                ))}
            </ul>
          ) : null)}
      </div>
    </div>
  );
}
