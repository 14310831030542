import snack from "components/wrapper/snack";
import { call, takeLatest, put } from "redux-saga/effects";
import {
  accountSettingsUpdateStart,
  accountSettingsUpdateFail,
  sendOTPtoMailStart,
  verifyOTPStart,
  sendOTPUsingFirebaseStart,
  sendOTPUsingFirebaseFail,
  setLoading,
  showVerifyOTPSuccess,
  showVerifyOTPStart,
  setShowEmailOtpComponent,
  sendOTPtoMailSuccess,
  sendOTPtoMailFail,
  setOtpScreen
} from 'redux/accountSettings';
import { history } from "routes";
import jwt from "jwt-decode";
import { sendOTPtoMail, updateSetting } from "redux/services/accountSettings";
import { getUserStart, } from "redux/user";
import { getAccessToken, } from "utils/auth";
import { isPhoneNumber } from "utils/validations";
import { sendOTPWithFb } from "redux/services/fbApi";

function* accountSettingsWatcher() {
  yield takeLatest(
    [accountSettingsUpdateStart.type,
    sendOTPtoMailStart.type,
    verifyOTPStart.type,
    sendOTPUsingFirebaseStart.type],
    accountSettingsWorker,
  );
}

function* accountSettingsWorker(action: any): any {
  try {
    if (getAccessToken()) {
      switch (action.type) {
        case accountSettingsUpdateStart.type: {
          const response: any = yield call(updateSetting, action.payload);
          if (!response.ResponseBody.passwordUpdated) {
            localStorage.removeItem('access-token');
            localStorage.setItem('access-token', response.ResponseBody.token);
            yield put (setLoading({loading: false}));
            yield put (setOtpScreen({showOtpScreen: false}))
            yield put(getUserStart());
            history.push('/account-settings');
            snack.success('Account setting updated Successfully');
          } else {
            if (action.payload.currentPassword && action.payload.newPassword) {
              history.push('/account-settings');
              snack.success('Password updated Successfully');
            }
          }
        }
          break;

        case sendOTPtoMailStart.type: {
          const user: any = jwt(JSON.stringify(getAccessToken()));
          action.payload.userId = user._id;
          yield call(sendOTPtoMail, action.payload);
          yield put(setShowEmailOtpComponent({ showEmailOTPComponent: true }));
          yield put(sendOTPtoMailSuccess())
          snack.success('Email sent Successfully');
        }
          break;

        case sendOTPUsingFirebaseStart.type: {
          let isPhoneNumberValid = isPhoneNumber(action.payload.phoneNumber);

          if (!isPhoneNumberValid) {
            snack.error("Enter a valid phone number to receive OTP");
            return;
          }

          yield put(setLoading({ loading: true }));
          yield put(showVerifyOTPStart());
          const result = yield call(sendOTPWithFb, {
            phoneNumber: action.payload.countryCode + action.payload.phoneNumber, containerName: "recaptcha-container", appVerifier: null
          });

          yield put(setLoading({ loading: false }));

          if (result.captchaBoolean) {
            yield put(showVerifyOTPSuccess({ appVerifier: result.appVerifier }));
            snack.success('An OTP has been sent to you mobile');
          } else {
            snack.error("Unable to send OTP");
          }
        }
          break;

        case verifyOTPStart.type: {
          const response: any = yield call(updateSetting, action.payload);
          yield put(setShowEmailOtpComponent({ showEmailOTPComponent: false }));
          localStorage.removeItem('access-token');
          localStorage.setItem('access-token', response.ResponseBody.token);
          yield put(getUserStart());
          snack.success('Email Updated Successfully')
          history.push('/account-settings');
        }
          break;

        default:
          break;
      }
    }
    else {
      console.error('error updating user settings');
    }
  } catch (err: any) {
    if (action.type === accountSettingsUpdateStart.type) {
      if (err.response?.data?.message === "emailUnverified") {
        yield put(accountSettingsUpdateFail({
          msg: 'Email is not verified. Please verify your email or resend the email verification link',
          error: err
        }));
      } else if(err.response?.data?.message === "You have entered wrong password") {
        yield put(accountSettingsUpdateFail({
          msg: 'You have entered wrong password',
          error: err
        }));
        snack.error (err.response?.data?.message);
      } else if(err.response?.data?.message === "New password is similar to current password") {
        yield put(accountSettingsUpdateFail({
          msg: 'New password is similar to current password',
          error: err
        }));
        snack.error (err.response?.data?.message);
      }
      else {
        yield put(accountSettingsUpdateFail({ err }));
      }

    }
    if (action.type === sendOTPUsingFirebaseStart.type) {
      yield put(sendOTPUsingFirebaseFail({
        msg: 'Unable to send OTP',
        error: err
      }));
    }
    if (action.type === sendOTPtoMailStart.type) {
      if(err.response?.data?.message) {
        // snack.error (err.response?.data?.message);
      }
      yield put(sendOTPtoMailFail({
        msg: 'Unable to send OTP',
        error: err
      }));
    }

    if (action.type === verifyOTPStart.type) {
      yield put(setShowEmailOtpComponent({ showEmailOTPComponent: false }));
      yield put(sendOTPtoMailFail({
        msg: 'Unable to send OTP',
        error: err
      }));
      snack.error ('Unable to send OTP');
    }
  }
}

export default accountSettingsWatcher;
