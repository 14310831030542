import {_axiosInstance} from "api/axiosController";

export const getBagsProducts = async (query: number) => {
  let uri = `/search/v1/get?query=bags`;
  try {
    const res:any = await _axiosInstance.get(uri);
    console.log ({bags : res })
    return res.data;
  } catch (e) {
    throw e;
  }
};

