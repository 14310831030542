import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";

export const getSecretKeyData = async (data: any) => { // TODO : create 'type' for payment data and replacw with 'any'.
  let uri = '/payment/stripe';
  try {
    const res = await axios.post(uri, data, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    console.log(e)
    throw e;
  }
};

export const getStripeCheckoutUrl = async (data: any) => {
  let uri = '/payment/stripe-checkout';
  try {
    const res = await axios.post(uri, data, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    console.log(e)
    throw e;
  }
};

export const updateStripePaymentIntent = async (data: any) => {
  let uri = '/payment/stripe/update/payment-intent';
  try {
    const res = await axios.post(uri, data, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const createOrderIntent = async (data: any) => {
  let uri = '/payment/paypal';
  try {
    const res = await axios.post(uri, data, { headers: { "Authorization": `Bearer ${getAccessToken()}` } })
    return res.data
  } catch (e) {
    throw e
  }
}

export const getPaymentMethods = async () => {
  let uri = `/payment/stripe/payment-methods/list`;
  try {
    const res = await axios.get(uri,{ headers: { "Authorization": `Bearer ${getAccessToken()}` } })
    return res.data
  } catch (e) {
    throw e
  }
}

export const deletePaymentMethod = async (paymentMethodId: string) => {
  let uri = `/payment/stripe/payment-methods/delete/${paymentMethodId}`;
  console.log(uri);
  try {
    const res = await axios.delete(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } })
    return res.data
  } catch (e) {
    throw e
  }
}


export const addPaymentMethod = async (data: any) => {
  let uri = '/payment/stripe/payment-methods/add';
  try {
    const res = await axios.post(uri, data, { headers: { "Authorization": `Bearer ${getAccessToken()}` } })
    return res.data
  } catch (e) {
    throw e
  }
}
