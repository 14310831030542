import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  addresses: any;
  error: boolean;
  deliveryAddress: any
}

const initialState: State = {
  isLoading: true,
  addresses: [],
  deliveryAddress: {},
  error: false
};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    addNewAddressStart : (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    addNewAddressSuccess: (state: State, action) => {
      state.addresses = action.payload.addresses;
    },
    addNewAddressFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    removeAddressStart: (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    removeAddressSuccess: (state: State, action) => ({
      ...state,
      addresses : action.payload.addresses

    }),
    removeAddressFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    getAddressesStart : (state: State) => ({
      ...initialState,
      isLoading: true
    }),
    setAddressAsDefaultStart: (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    setAddressAsDefaultSuccess : (state: State, action) => ({
      ...state,
      deliveryAddress: action.payload,
      isLoading: false
    }),
    setAddressAsDefaultFailure : (state: State, action) => ({
      ...state,
      error: true,
      isLoading: false
    })
  }
});

export const {
  addNewAddressStart,
  addNewAddressFailure,
  addNewAddressSuccess,
  getAddressesStart,
  removeAddressFailure,
  removeAddressStart,
  removeAddressSuccess,
  setAddressAsDefaultSuccess,
  setAddressAsDefaultStart
} = addressSlice.actions;
export default addressSlice.reducer;
