import axios from "api/axiosController";

export const updateSetting = async (data: any) => {
  let uri = '';
  if (data.currentPassword && data.newPassword) {
    uri = '/user/change-password';
  } else {
    uri = '/user/update-setting';
  }

  try {
    const res = await axios.post(uri, data);
    console.log(res.data)
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const sendOTPtoMail = async (data: any) => {
  let uri = '/user/verify-email';
  try {
    const res = await axios.post(uri, data);
    return res.data;
  } catch (e) {
    throw e;
  }
}
