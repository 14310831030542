import { call, put, takeLatest } from "redux-saga/effects";
import {
  getImageStart,
  getImageSuccess
} from 'redux/product';
import { getProductImage } from "redux/services/product";

function* imageSagaWatcher() {
    yield takeLatest(
      [getImageStart.type],
      imageWorker,
    );
  }


function* imageWorker(action: any): any {
  try {
    switch (action.type) {
        case getImageStart.type: {
            const response: any = yield call(getProductImage, action.payload);
            yield put (getImageSuccess({ image: response.data }))
        };
        break;

        default:
        break;
  }
}
  catch (err:any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
  }
}

export default imageSagaWatcher;
