import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';
import { cancelSubscription, getMySubscriptions, updateSubscription } from 'redux/services/subscription';
import { getMySubscriptionsFailure, getMySubscriptionsStart, getMySubscriptionsSuccess, cancelSubscriptionStart, updateMySubscriptionsStart } from 'redux/subscription';


function* subscriptionSagaWatcher() {
  yield takeLatest(
    [getMySubscriptionsStart.type, cancelSubscriptionStart.type, updateMySubscriptionsStart.type],
    subscriptionSagaWorker,
  );
}

function* subscriptionSagaWorker(action: any): any {
  try {
    switch (action.type) {
      case getMySubscriptionsStart.type: {
        const data = yield call(getMySubscriptions);
        yield put(getMySubscriptionsSuccess({ mySubscriptions: data.ResponseBody.subscriptions }));
        break;
      }

      case cancelSubscriptionStart.type: {
        const data = yield call(cancelSubscription, action.payload.subscriptionId);
        yield put(getMySubscriptionsSuccess({ mySubscriptions: data.ResponseBody.subscriptions }));
        break;
      }

      case updateMySubscriptionsStart.type: {
        const data = yield call(updateSubscription, action.payload);
        yield put(getMySubscriptionsSuccess({ mySubscriptions: data.ResponseBody.subscriptions }));
        break;
      }

      default:
        break;
    }
  } catch (err) {

    console.log(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getMySubscriptionsStart.type || action.type === updateMySubscriptionsStart.type || action.type === cancelSubscriptionStart.type) {
      yield put(getMySubscriptionsFailure(err));
    }
  }
}

export default subscriptionSagaWatcher;
