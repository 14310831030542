import { createSlice } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  error: boolean;
  deliveryDetails: any;
}

const initialState: State = {
  isLoading: true,
  error: false,
  deliveryDetails: {},
};

export const deliveryitem = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    getDeliveryChargeStart: (state, action) => ({
      ...initialState,
      isLoading: true,
      deliveryDetails: {},
    }),
    getDeliveryChargeError: (state, action) => ({
      ...initialState,
      isLoading: false,
      deliveryDetails: {},
      error: true,
    }),
    getDeliveryChargeSuccess: (state, action) => ({
      ...initialState,
      isLoading: false,
      deliveryDetails: action.payload.deliveryDetails,
    }),
  },
});

export const {
  getDeliveryChargeStart,
  getDeliveryChargeSuccess,
  getDeliveryChargeError,
} = deliveryitem.actions;
export default deliveryitem.reducer;
