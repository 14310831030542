import snack from "components/wrapper/snack";
import { call, put, takeLatest } from "redux-saga/effects";
import { clearCartData, getCartItemsStart } from "redux/cart";
import {
  createOrderStart,
  createOrderSuccess,
  createOrderFailure,
  getOrdersStart,

} from 'redux/order';
import { createOrder, getOrders } from "redux/services/order";
import { getAccessToken } from "utils/auth";

function* OrderSagaWatcher() {
  yield takeLatest(
    [
      createOrderStart.type,
      getOrdersStart.type
    ],
    orderWorker,
  );
}

function* orderWorker(action: any): any {
  try {
    if (getAccessToken()) {
      switch (action.type) {
        // eslint-disable-next-line no-lone-blocks
        case createOrderStart.type: {
          if (getAccessToken()) {
            yield call(createOrder, action.payload);
            yield put(clearCartData());
            yield put (getCartItemsStart());
            snack.success('Order created successfully');
          } else {
            console.log('Unauthorized');
          }
        }
          break;

        case getOrdersStart.type: {
          const response: any = yield call(getOrders, action.payload);
          yield put(createOrderSuccess({ orders: response.ResponseBody }));
        }
          break;

        default: console.error('invalid case');
          break;

      }
    } else {
      console.log('Unauthorized');
    }

  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === createOrderStart.type) {
      yield put(createOrderFailure);
    } else if (action.type === getOrdersStart.type) {
      console.log('error in getting order');
    }
  }
}

export default OrderSagaWatcher;
