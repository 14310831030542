import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  electronics?: any;
  error?: boolean;
  msg?: string
}

const initialState: State = {
  isLoading: false,
  electronics: [],
  error: false,
  msg: ''
};

export const electronicsSlice = createSlice({
  name: 'homepage-section-electronics',
  initialState,
  reducers: {
    getelectronicsProductsStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    getelectronicsProductsSuccess: (state: State, action) => ({
      ...state,
      electronics: action.payload.result
    }),
    getelectronicsProductsFailure: (state: State, action) => ({
      ...state,
      error: action.payload.error,
      msg: action.payload.msg
    })
  }
});

export const {
  getelectronicsProductsStart,
  getelectronicsProductsSuccess,
  getelectronicsProductsFailure
} = electronicsSlice.actions;
export default electronicsSlice.reducer;
