import { createSlice } from "@reduxjs/toolkit";

export const dropdownCategoriesSlice = createSlice({
  name: "dropdownCategories",
  initialState: {
    dropdownCategories: [],
    isLoading: false,
  },
  reducers: {
    getDropdownCategoriesFetch: (state) => {
      state.isLoading = true;
    },
    getDropdownCategoriesSuccess: (state, action) => {
      state.dropdownCategories = action.payload;
      state.isLoading = false;
    },
    getDropdownCategoriesFailure: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  getDropdownCategoriesFetch,
  getDropdownCategoriesSuccess,
  getDropdownCategoriesFailure,
} = dropdownCategoriesSlice.actions;

export default dropdownCategoriesSlice.reducer;
