import { call, put, takeLatest } from "redux-saga/effects";
import {
  gettravelProductsStart,
  gettravelProductsSuccess,
  gettravelProductsFailure

} from 'redux/Home/travel';
import { gettravelProducts } from "redux/services/Home/travel";

function* travelSagaWatcher() {
  yield takeLatest(
    [
      gettravelProductsStart.type
    ],
    travelWorker,
  );
}

function* travelWorker(action: any): any {
  try {
    switch (action.type) {
      case gettravelProductsStart.type: {
        const response: any = yield call(gettravelProducts, action.payload.category);
        yield put(gettravelProductsSuccess({
          category: response.ResponseBody.category,
          result: response.ResponseBody.result,
        }));
      }
        break;

      default: console.error('invalid case');
        break;
    }

  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === gettravelProductsStart.type) {
      yield put(gettravelProductsFailure(action.payload.category));
    } else if (action.type === gettravelProductsStart.type) {
      console.log('error in getting travel products');
    }
  }
}

export default travelSagaWatcher;
