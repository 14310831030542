import { Route, Switch, useLocation } from "react-router-dom";
import Footer from "components/Layout/SiteFooter";
import routes from "./routeList";
import Header from "components/Layout/Header";
import {
  FieldDictionary,
  SearchProvider,
  Pipeline,
  FilterBuilder,
  RangeFilterBuilder,
} from "@sajari/react-search-ui";
import "./routes.css";
import useWindowSize from "utils/useWindowSize";
import MobileViewLogin from "components/MobileViewLogin/MobileViewLogin";
const CustomRoutes: React.FC = () => {
  const fields = new FieldDictionary({
    title: "name",
  });
  const pipeline = new Pipeline(
    {
      account: "1640286447936078869",
      collection: "paysfer-collection",
    },
    "app"
  );
  const priceFilter = new RangeFilterBuilder({
    name: "price0",
    field: "price0",
  });

  const genderFilter = new FilterBuilder({
    name: "gender0",
    field: "gender0",
  });

  const sizeFilter = new FilterBuilder({
    name: "size0",
    field: "size0",
  });

  const colorFilter = new FilterBuilder({
    name: "color",
    field: "color",
    count: true,
    array: true,
  });

  const categoryFilter = new FilterBuilder({
    name: "category",
    field: "category",
  });

  const brandFilter = new FilterBuilder({
    name: "brand",
    field: "brand",
  });

  const location = useLocation(); // Get the current location

  const getHeader = () => {
    if (
      location.pathname === "/signin" ||
      location.pathname === "/users/buyer/register" ||
      location.pathname === "/sell-like-item"
    ) {
      return false;
    } else {
      return true;
    }
  };
  const size = useWindowSize();
  return (
    <SearchProvider
      search={{
        pipeline,
        fields,
        filters: [
          colorFilter,
          priceFilter,
          genderFilter,
          sizeFilter,
          categoryFilter,
          brandFilter,
        ],
      }}
      searchOnLoad={true}
    >
      <div style={{ display: getHeader() ? "" : "None" }}>
        <Header />
      </div>
      <div
        className={getHeader() ? "page-content" : "page-content-no-header"}
        style={
          size.x < 1000 &&
          !(
            window.location.pathname === "/signin" ||
            window.location.pathname === "/users/buyer/register" ||
            window.location.pathname === "/sell-like-item"
          )
            ? { marginTop: "216px" }
            : {}
        }
      >
        <Switch>
          {routes.map(({ path, exact, ...rest }) => {
            return (
              <Route
                path={path}
                key={path}
                exact={exact}
                render={() => {
                  return (
                    <>
                      {rest.component}
                      {path === "/" ? <Footer /> : null}
                    </>
                  );
                }}
              />
            );
          })}
        </Switch>
        <MobileViewLogin />
      </div>
    </SearchProvider>
  );
};

export default CustomRoutes;
