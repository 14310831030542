import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";

export const updateCartItems = async (data:any) => {
  let uri = '/userItems';
    try {
      const res = await axios.post(uri, data,{ headers: {"Authorization" : `Bearer ${getAccessToken()}`} });
      return res.data;
    } catch (e) {
      throw e;
    }
};

export const removeCartItems = async (userItemId:any) => {
  let uri = `/userItems/${userItemId}`;

    try {
      const res = await axios.delete (uri, { headers: {"Authorization" : `Bearer ${getAccessToken()}`} });
      return res.data;
    } catch (e) {
      throw e;
    }
};


export const getCartItems = async () => {
  let uri = `/userItems/cart`;

    try {
      const res = await axios.get(uri, { headers: {"Authorization" : `Bearer ${getAccessToken()}`}});
      return res.data;
    } catch (e) {
      throw e;
    }
};
