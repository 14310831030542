import { createSlice } from "@reduxjs/toolkit";
// page number and limit

interface State {
  orderIntentId: string;
  error: boolean;
  isLoading: boolean;
}

const initialState: State = {
  orderIntentId: "",
  error: false,
  isLoading: false,
};

export const paymentSlice = createSlice({
  name: "paypal-payment",
  initialState,
  reducers: {
    initiatePaypalPaymentStart: (state: State, action) => ({
      ...initialState,
      error: false,
      isLoading: true,
    }),
    initiatePaypalPaymentSuccess: (state: State, action) => ({
      ...state,
      orderIntentId: action.payload.orderIntentId,
      isLoading: false,
    }),
    initiatePaypalPaymentFailure: (state: State, action) => ({
      ...state,
      error: action.payload,
      isLoading: false,
    }),
  },
});

export const {
  initiatePaypalPaymentStart,
  initiatePaypalPaymentSuccess,
  initiatePaypalPaymentFailure,
} = paymentSlice.actions;

export default paymentSlice.reducer;
