import { Helmet } from "react-helmet";

export default function ShopMetaTags(props: { seller: any }) {
  const { seller } = props;

  const Seller_Shop_Name = seller?.shopName || seller?.firstName || "";

  console.log(seller);
  return (
    <>
      <Helmet>
        <title>Paysfer | Welcome to {Seller_Shop_Name}'s Shop</title>
        <meta
          name="description"
          content={`Buy unique products from ${Seller_Shop_Name}'s shop on Paysfer.`}
        />
        <meta
          name="keywords"
          content={`${Seller_Shop_Name}, Paysfer eMart, Paysfer`}
        />
        <link
          rel="canonical"
          href={`https://www.paysfer.com/shops/${seller.sellerId}`}
        />
        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={`Paysfer | Welcome to ${Seller_Shop_Name}'s Shop`}
        />
        <meta
          property="og:description"
          content={`Buy unique products from ${Seller_Shop_Name}'s shop on Paysfer.`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.paysfer.com/shops/${seller.sellerId}`}
        />
        <meta
          property="og:image"
          content="https://blackbow.s3-us-west-2.amazonaws.com/Items/W/W8520.jpg"
        />
        <meta property="og:site_name" content="Paysfer" />
        <meta property="og:locale" content="en_US" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "Paysfer eMart | Jewelry",
            description: `Buy unique products from ${Seller_Shop_Name}'s shop on Paysfer.`,
            url: `https://www.paysfer.com/shops/${seller.sellerId}`,
            image:
              "https://blackbow.s3-us-west-2.amazonaws.com/Items/W/W8520.jpg",
            author: {
              "@type": "Organization",
              name: "Paysfer",
            },
          })}
        </script>
      </Helmet>
    </>
  );
}
