import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";

export const createOrder = async (data: any) => {
  let uri = `/orders`;

  try {
    const res = await axios.post(uri, data, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getOrders = async (data: any) => {
  const { status, duration, page } = data;

  let uri = `/orders/history?status=${status}&date=${duration}&page=${page}`;

  try {
    const res = await axios.get(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    throw e;
  }
};
