import { useState, useEffect } from "react";

const useWindowSize = () => {
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    });
  useEffect(() => {
    window.addEventListener("resize", updateSize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);
  return size;
};

export default useWindowSize;
