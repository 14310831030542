// @ts-nocheck
import React, { useState, Dispatch, SetStateAction } from "react";
import {
  TextField,
  Link,
  CircularProgress,
  IconButton,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { SignInUser } from "types";
import { isEmail, isPhoneNumber } from "utils/validations";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginStart } from "redux/user";
import { RootState } from "redux/store";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { updateMobileState } from "redux/mobileLogin";
import "./style.css";
import Grid from "@mui/material/Grid";
interface InputProps {
  user: SignInUser;
  setUser: Dispatch<SetStateAction<SignInUser>>;
}

interface CreateAccountProps {
  createAccountClick: React.MouseEventHandler<HTMLAnchorElement>;
}

export const CreateAccount: React.FC<CreateAccountProps> = ({
  createAccountClick,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="auth-bottom-linking">
      <div
        className="auth-bottom-content"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="primary_buttonContainer"
          onClick={() => dispatch(updateMobileState(false))}
        >
          <Link
            className="btn btn-primary createNewAccount"
            onClick={createAccountClick}
          >
            Create new account
          </Link>
        </div>
      </div>
    </div>
  );
};

const SignIn: React.FC<InputProps> = ({ user, setUser }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const [userDetials, setUserDetails] = React.useState({
    userName: "",
    userPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const userData: any = useSelector((state: RootState) => state.user);
  const isLoading = useSelector((state: RootState) => state.user.isLoading);
  const emailVerificationSigninErr = useSelector(
    (state: RootState) => state.user.emailVerificationSigninErr
  );
  const msg = useSelector((state: RootState) => state.user.message);

  const [error, setError] = useState({
    emailOrPhone: {
      err: false,
      msg: "",
    },
    password: {
      err: false,
      msg: "",
    },
    signin: {
      err: false,
      msg: "",
    },
  });

  // useEffect(() => {

  //   setError((prevState) => ({
  //     ...prevState,
  //     ...error
  //   }));

  // }, [userData.error, error]);

  const isEmptyFields = () => {
    let isfieldsInvalid = true;
    let errors: any = {};
    if (!user.emailOrPhone) {
      errors = {
        ...errors,
        emailOrPhone: {
          err: true,
          msg: "Required",
        },
      };
    }

    if (!user.password) {
      errors = {
        ...errors,
        password: {
          err: true,
          msg: "Required",
        },
      };
    }

    isfieldsInvalid = !!(errors?.emailOrPhone?.err || errors?.password?.err);

    setError((prevState) => ({
      ...prevState,
      ...errors,
    }));

    return isfieldsInvalid;
  };

  const loginUser = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (isEmptyFields()) return;

    if (!error.emailOrPhone.err && user.password) {
      if (location.state && location.state.buyNowFlow) {
        dispatch(
          loginStart({
            user,
            history,
            buyNowFlow: location.state.buyNowFlow,
            redirect: location.state?.redirect,
          })
        );
      } else
        dispatch(
          loginStart({ user, history, redirect: location.state?.redirect })
        );
    }
  };

  const validateEmailOrPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    let msg: string = "",
      err: boolean = false;

    let emailOrPhone = e.target.value;

    if (!isEmail(emailOrPhone) && !isPhoneNumber(emailOrPhone)) {
      err = true;
      msg = "Enter a valid email";
    } else if (
      (!isEmail(emailOrPhone) && isPhoneNumber(emailOrPhone)) ||
      (isEmail(emailOrPhone) && !isPhoneNumber(emailOrPhone))
    ) {
      err = false;
      setUserDetails({ ...userDetials, userName: emailOrPhone });
    }

    setError((prevState) => ({
      ...prevState,
      emailOrPhone: {
        err,
        msg,
      },
    }));
  };
  return (
    <section className="auth-section">
      <div className="auth-form-wrap">
        <form onSubmit={loginUser}>
          {!user.emailOrPhone ? (
            <div className="form-group">
              <label>Email</label>
              <TextField
                error={error.emailOrPhone.err}
                className={"mui-text-field"}
                color="primary"
                variant="outlined"
                margin="normal"
                fullWidth
                id="emailOrPhone"
                name="emailOrPhone"
                helperText={error.emailOrPhone.msg}
                onChange={validateEmailOrPhone}
                placeholder="Email or mobile phone number"
                type="email"
                autoComplete="on"
              />

              {/* Invisible  */}
              <OutlinedInput
                  style={{display:"none"}}
                  type="password"/>
              
              <Grid
                container
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      marginTop: "10px",
                    }}
                    onClick={() => {
                      setUser({ ...user, emailOrPhone: userDetials.userName });
                    }}
                  >
                    Continue
                  </button>
                </Grid>
              </Grid>
            </div>
          ) : (
            <React.Fragment>
              <div className="form-group">
                <label>Password</label>
                <OutlinedInput
                  placeholder="Password"
                  className={"mui-text-field"}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  color="primary"
                  fullWidth
                  name="password"
                  onChange={(e) => {
                    setError({ ...error, password: { err: false, msg: "" } });
                    setUser({ ...user, password: e.target.value });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                        edge="end"
                      >
                        {showPassword ? (
                          <span
                            className="password-visibility"
                            style={{ fontSize: "10px" }}
                          >
                            Hide
                          </span>
                        ) : (
                          <span
                            className="password-visibility"
                            style={{ fontSize: "10px" }}
                          >
                            Show
                          </span>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {error.password.msg && (
                  <FormHelperText error>{error.password.msg}</FormHelperText>
                )}
              </div>
              <div id="recaptcha-container" />
              <div className="auth-btn-wrap align-items-center justify-content-between">
                <button
                  type="submit"
                  disabled={isLoading ? true : false}
                  className="btn btn-primary"
                >
                  {isLoading ? <CircularProgress /> : <>Sign in</>}
                </button>
                {emailVerificationSigninErr ? (
                  <>
                    {/* <div className={'errMsgDiv'}>
                    <span className={'signinErrSpan'}> {msg} </span>
                  </div> */}
                    <div className="resend-wrap">
                      <Link
                        className="btn btn-primary"
                        onClick={() => history.push("/resend-email")}
                      >
                        <strong>Resend</strong>
                      </Link>
                    </div>
                  </>
                ) : null}
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <Link
                        onClick={() =>
                          setUser({
                            ...user,
                            emailOrPhone: "",
                          })
                        }
                        className="btn btn-primary"
                        style={{
                          color: "black",
                          cursor: "pointer",
                        }}
                      >
                        Back
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        className="btn btn-primary forgot-pwd"
                        onClick={() => history.push("/forgot-password")}
                      >
                        Forgot Password
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>
          )}
        </form>
      </div>
      {/* <p style={{ fontSize: "10px" }} className="hideMobileView">
        By continuing, you agree to <Link>Paysfer's Conditions</Link> of Use and
        Privacy Notice
      </p> */}
    </section>
  );
};

export default SignIn;
