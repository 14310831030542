import { call, put, takeLatest } from "redux-saga/effects";
import { getAccountBalanceStart, getAccountBalanceSuccess, redeemEgiftCardStart, redeemEgiftCardSuccess } from "redux/paysferAccount";
import { getPaysferBalance, redeemPaysferGiftCard } from "redux/services/paysferAccount";


function* paysferAccountSagaWatcher() {
  yield takeLatest(
    [
      getAccountBalanceStart.type,
      redeemEgiftCardStart.type
    ],
    paysferAccountdWorker
  );
}

function* paysferAccountdWorker(action: any): any {
  try {
    switch (action.type) {
      case getAccountBalanceStart.type:
        {
          const response: any = yield call(
            getPaysferBalance
          );
          console.log("response", response);

          yield put(getAccountBalanceSuccess(response.ResponseBody));
        }
        break;
        case redeemEgiftCardStart.type:
        {
          const response: any = yield call(
            redeemPaysferGiftCard,action.payload
          );
          console.log("redeemPaysferGiftCardredeemPaysferGiftCard", response);

          yield put(redeemEgiftCardSuccess());
        }
        break;
     

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
  }
}

export default paysferAccountSagaWatcher;
