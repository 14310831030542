import { call, put, takeLatest } from "redux-saga/effects";
import {
  getmobileProductsStart,
  getmobileProductsSuccess,
  getmobileProductsFailure

} from 'redux/Home/mobile';
import { getMobileProducts } from "redux/services/Home/mobile";

function* mobileSagaWatcher() {
  yield takeLatest(
    [
      getmobileProductsStart.type
    ],
    mobileWorker,
  );
}

function* mobileWorker(action: any): any {
  try {
    switch (action.type) {
      case getmobileProductsStart.type: {
        const response: any = yield call(getMobileProducts, action.payload.category);
        yield put(getmobileProductsSuccess({
          category: response.ResponseBody.category,
          result: response.ResponseBody.result,
        }));
      }
        break;

      default: console.error('invalid case');
        break;
    }

  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getmobileProductsStart.type) {
      yield put(getmobileProductsFailure(action.payload.category));
    } else if (action.type === getmobileProductsStart.type) {
      console.log('error in getting mobile products');
    }
  }
}

export default mobileSagaWatcher;
