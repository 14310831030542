import snack from "components/wrapper/snack";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  getSellersStart,
  getSellersSuccess,
  cancelSellers,
  getProductsStart,
  getProductsSuccess,
  cancelProducts,
  updateShopDetailsStart,
  updateShopDetailsSuccess,
  updateShopDetailsError,
  getSellerDetailsStart,
  getSellerDetailsSuccess,
  getSellerDetailsError,
  updateOnlyProductsStart,
  updateOnlyProductsSuccess,
} from "redux/sellers";
import {
  getAllProduct,
  getUsersAsSellers,
  getSellers,
  updateShopDetails,
  getSellerById,
} from "redux/services/sellers";
import { checkIsValidArray } from "utils";

function rearrangeCategories(categories: any) {
  const parentWithSubcategories = categories.filter(
    (category: any) => category.subCategories.length > 0
  );

  const parentWithoutSubcategories = categories.filter(
    (category: any) => category.subCategories.length === 0
  );

  return parentWithSubcategories.concat(parentWithoutSubcategories);
}

function* sellersSagaWatcher() {
  yield takeLatest(
    [
      getSellersStart.type,
      getSellerDetailsStart.type,
      getProductsStart.type,
      updateShopDetailsStart.type,
      updateOnlyProductsStart.type,
    ],
    sellersWorker
  );
}

function* sellersWorker(action: any): any {
  try {
    switch (action.type) {
      case getSellerDetailsStart.type:
        {
          const seller = yield call(getSellerById, action.payload.SellerId);
          yield put(
            getSellerDetailsSuccess({
              seller,
            })
          );
        }
        break;
      case updateShopDetailsStart.type:
        {
          const shopDetails = yield call(
            updateShopDetails,
            action.payload.shopDetails
          );
          if (!shopDetails.error) {
            snack.success(shopDetails.mes);
            yield put(
              updateShopDetailsSuccess({
                shopName: shopDetails.shopName,
              })
            );
          } else {
            snack.error(shopDetails.mes);
            yield put(updateShopDetailsError({}));
          }
        }
        break;
      case getSellersStart.type:
        {
          const response: any = yield call(getSellers);
          const userSellersResponse: any = yield call(getUsersAsSellers);
          const userAsSellerData =
            userSellersResponse.ResponseBody &&
            Array.isArray(userSellersResponse.ResponseBody)
              ? userSellersResponse.ResponseBody.map((eachSeller: any) => ({
                  ...eachSeller,
                  sellerId: eachSeller.sellerId || eachSeller.sellerid,
                }))
              : [];
          yield put(
            getSellersSuccess(response.ResponseBody.concat(userAsSellerData))
          );
        }
        break;
      case getProductsStart.type:
      case updateOnlyProductsStart.type:
        {
          const response: any = yield call(getAllProduct, action.payload);
          let parentCategoryDetails: any = [];
          if (checkIsValidArray(response.ResponseBody?.products)) {
            const currentProducts = response.ResponseBody?.products;

            const topLevelCategories: any = {};

            currentProducts.forEach(
              ({ parentCategoryIdList, parentCategoryNameList }: any) => {
                const topLevelCategoryId = parentCategoryIdList[0];
                if (topLevelCategoryId) {
                  const topLevelCategoryName = (
                    parentCategoryNameList[0] || ""
                  ).trim();

                  if (!topLevelCategories[topLevelCategoryId]) {
                    topLevelCategories[topLevelCategoryId] = {
                      categoryId: topLevelCategoryId,
                      categoryName: topLevelCategoryName,
                      subCategories: [],
                    };
                  }

                  let currentLevel =
                    topLevelCategories[topLevelCategoryId].subCategories;
                  for (let i = 1; i < parentCategoryIdList.length; i++) {
                    const currentId = parentCategoryIdList[i];
                    if (currentId) {
                      const currentName = (
                        parentCategoryNameList[i] || ""
                      ).trim();
                      const existingCategory = currentLevel.find(
                        (cat: any) => cat.categoryId === currentId
                      );

                      if (existingCategory) {
                        currentLevel = existingCategory.subCategories;
                      } else {
                        const newCategory = {
                          categoryId: currentId,
                          categoryName: currentName,
                          subCategories: [],
                        };
                        currentLevel.push(newCategory);
                        currentLevel = newCategory.subCategories;
                      }
                    }
                  }
                }
              }
            );
            if (Object.values(topLevelCategories).length <= 5) {
              let conditionToCheckParentCategoriesLimitation =
                5 - Object.values(topLevelCategories).length;
              if (conditionToCheckParentCategoriesLimitation > 0) {
                parentCategoryDetails = Object.values(
                  topLevelCategories
                ).flatMap((parentCategory: any) => {
                  if (
                    checkIsValidArray(parentCategory.subCategories) &&
                    conditionToCheckParentCategoriesLimitation > 0
                  ) {
                    const dividedCategories: any[] = [];
                    parentCategory.subCategories = parentCategory.subCategories
                      .map((subCategory: any) => {
                        if (conditionToCheckParentCategoriesLimitation >= 0) {
                          dividedCategories.push({
                            ...subCategory,
                          });
                          return undefined;
                        } else {
                          return subCategory;
                        }
                      })
                      .filter((val: any) => !!val);
                    conditionToCheckParentCategoriesLimitation =
                      conditionToCheckParentCategoriesLimitation - 1;
                    return [parentCategory].concat(dividedCategories);
                  } else {
                    return [parentCategory];
                  }
                });
              } else {
                parentCategoryDetails =
                  Object.values(topLevelCategories).length;
              }
            } else {
              parentCategoryDetails = Object.values(topLevelCategories).length;
            }
          }
          parentCategoryDetails = rearrangeCategories(parentCategoryDetails);
          if (action.type === getProductsStart.type) {
            yield put(
              getProductsSuccess({
                response: response.ResponseBody,
                parentCategoryDetails,
              })
            );
          } else if (action.type === updateOnlyProductsStart.type) {
            yield put(
              updateOnlyProductsSuccess({
                response: response.ResponseBody,
                parentCategoryDetails,
              })
            );
          }
        }
        break;

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getSellerDetailsStart.type) {
      yield put(getSellerDetailsError({}));
    }
    if (action.type === updateShopDetailsStart.type) {
      yield put(updateShopDetailsError({}));
    }
    if (action.type === getSellersStart.type) {
      yield put(cancelSellers);
    }

    if (action.type === getProductsStart.type) {
      yield put(cancelProducts);
    }
  }
}

export default sellersSagaWatcher;
