import { useQuery, useSearch, useSearchContext } from "@sajari/react-hooks";
import { useHistory } from "react-router-dom";

function DropdownComponent({ childData }: any) {
  const { setQuery } = useQuery();
  const history = useHistory();
  const { searched } = useSearchContext();
  const { results = [] } = useSearch();

  const handleChangeCategory = (category: any) => {
    setQuery(category);
    history.push({
      pathname: "/search-results",
      search: `?${"q"}=${category}`,
      state: {
        searched: searched,
        results: results,
      },
    });
  };

  const renderMegaMenuContent = () => {
    let content;
    const { topCategories, brands } = childData;

    // Display two columns for both Top Categories and Top Brands in the dropdown.
    // Each column contains a list of items with an onClick event to handle category or brand changes.
    // If the number of Top Categories or Top Brands exceeds 30, a third column is added.
    // Note: Top Categories represent the top subcategories for a certain category,
    // and Brands are the corresponding brands associated with those categories.
    if (topCategories.length > 0 && brands.length > 0) {
      content = (
        <>
          <div className="header-dropdown-column">
            <h3 className="header-dropdown-heading">Top Categories</h3>
            <hr className="header-dropdown-separator" />
            <ul>
              {topCategories.map((category: any) => (
                <li
                  key={category}
                  onClick={() => handleChangeCategory(category)}
                >
                  {category}
                </li>
              ))}
            </ul>
          </div>
          <div className="header-dropdown-column">
            <h3 className="header-dropdown-heading">Top Brands</h3>
            <hr className="header-dropdown-separator" />
            <ul>
              {brands.map((brand: any) => (
                <li key={brand} onClick={() => handleChangeCategory(brand)}>
                  {brand}
                </li>
              ))}
            </ul>
          </div>
        </>
      );
    } else if (topCategories.length > 0 && brands.length <= 0) {
      if (topCategories.length <= 30) {
        // divide into exactly two columns for the dropdown
        content = (
          <>
            <div className="header-dropdown-column">
              <h3 className="header-dropdown-heading">Top Categories</h3>
              <hr className="header-dropdown-separator" />
              <ul>
                {topCategories
                  .slice(0, Math.ceil(topCategories.length / 2))
                  .map((category: any) => (
                    <li
                      key={category}
                      onClick={() => handleChangeCategory(category)}
                    >
                      {category}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="header-dropdown-column">
              <h3 className="header-dropdown-heading">Other Categories</h3>
              <hr className="header-dropdown-separator" />
              <ul>
                {topCategories
                  .slice(Math.ceil(topCategories.length / 2))
                  .map((category: any) => (
                    <li
                      key={category}
                      onClick={() => handleChangeCategory(category)}
                    >
                      {category}
                    </li>
                  ))}
              </ul>
            </div>
          </>
        );
      } else {
        // divide into exactly three columns for the dropdown
        content = (
          <>
            <div className="header-dropdown-column">
              <h3 className="header-dropdown-heading">Top Categories</h3>
              <hr className="header-dropdown-separator" />
              <ul>
                {topCategories
                  .slice(0, Math.ceil(topCategories.length / 3))
                  .map((category: any) => (
                    <li
                      key={category}
                      onClick={() => handleChangeCategory(category)}
                    >
                      {category}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="header-dropdown-column">
              <h3 className="header-dropdown-heading">Other Categories</h3>
              <hr className="header-dropdown-separator" />
              <ul>
                {topCategories
                  .slice(
                    Math.ceil(topCategories.length / 3),
                    2 * Math.ceil(topCategories.length / 3)
                  )
                  .map((category: any) => (
                    <li
                      key={category}
                      onClick={() => handleChangeCategory(category)}
                    >
                      {category}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="header-dropdown-column">
              <h3 className="header-dropdown-heading">More Categories</h3>
              <hr className="header-dropdown-separator" />
              <ul>
                {topCategories
                  .slice(2 * Math.ceil(topCategories.length / 3))
                  .map((category: any) => (
                    <li
                      key={category}
                      onClick={() => handleChangeCategory(category)}
                    >
                      {category}
                    </li>
                  ))}
              </ul>
            </div>
          </>
        );
      }
    } else if (topCategories.length <= 0 && brands.length > 0) {
      if (brands.length <= 30) {
        // divide into exactly two columns for the dropdown
        content = (
          <>
            <div className="header-dropdown-column">
              <h3 className="header-dropdown-heading">Top Brands</h3>
              <hr className="header-dropdown-separator" />
              <ul>
                {brands
                  .slice(0, Math.ceil(brands.length / 2))
                  .map((brand: any) => (
                    <li key={brand} onClick={() => handleChangeCategory(brand)}>
                      {brand}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="header-dropdown-column">
              <h3 className="header-dropdown-heading">Other Brands</h3>
              <hr className="header-dropdown-separator" />
              <ul>
                {brands
                  .slice(Math.ceil(brands.length / 2))
                  .map((brand: any) => (
                    <li key={brand} onClick={() => handleChangeCategory(brand)}>
                      {brand}
                    </li>
                  ))}
              </ul>
            </div>
          </>
        );
      } else {
        // divide into exactly three columns for the dropdown
        content = (
          <>
            <div className="header-dropdown-column">
              <h3 className="header-dropdown-heading">Top Brands</h3>
              <hr className="header-dropdown-separator" />
              <ul>
                {brands
                  .slice(0, Math.ceil(brands.length / 3))
                  .map((brand: any) => (
                    <li key={brand} onClick={() => handleChangeCategory(brand)}>
                      {brand}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="header-dropdown-column">
              <h3 className="header-dropdown-heading">Other Brands</h3>
              <hr className="header-dropdown-separator" />
              <ul>
                {brands
                  .slice(
                    Math.ceil(brands.length / 3),
                    2 * Math.ceil(brands.length / 3)
                  )
                  .map((brand: any) => (
                    <li key={brand} onClick={() => handleChangeCategory(brand)}>
                      {brand}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="header-dropdown-column">
              <h3 className="header-dropdown-heading">More Brands</h3>
              <hr className="header-dropdown-separator" />
              <ul>
                {brands
                  .slice(2 * Math.ceil(brands.length / 3))
                  .map((brand: any) => (
                    <li key={brand} onClick={() => handleChangeCategory(brand)}>
                      {brand}
                    </li>
                  ))}
              </ul>
            </div>
          </>
        );
      }
    }

    return content;
  };
  return <div className="header-dropdown-grid">{renderMegaMenuContent()}</div>;
}

export default DropdownComponent;
