import { Suspense } from "react";

interface wrapperType {
    children: JSX.Element;
    
  }
const LazyLoader = () => <p>Loading...</p>

const LazyLoadWrapper = ({children}:wrapperType) => {
  return <Suspense fallback= {<LazyLoader/>} >{children}</Suspense>;
};

export default LazyLoadWrapper;
