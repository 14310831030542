import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  travel?: any;
  error?: boolean;
  msg?: string
}

const initialState: State = {
  isLoading: false,
  travel: [],
  error: false,
  msg: ''
};

export const travelSlice = createSlice({
  name: 'homepage-section-travel',
  initialState,
  reducers: {
    gettravelProductsStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    gettravelProductsSuccess: (state: State, action) => ({
      ...state,
      travel: action.payload.result
    }),
    gettravelProductsFailure: (state: State, action) => ({
      ...state,
      error: action.payload.error,
      msg: action.payload.msg
    })
  }
});

export const {
  gettravelProductsStart,
  gettravelProductsSuccess,
  gettravelProductsFailure
} = travelSlice.actions;
export default travelSlice.reducer;
