import { _axiosInstance } from "api/axiosController";

export const getElectronicsProducts = async (query: number) => {
  let uri = `/search/v1/get?query=Electronics`;
  try {
    const res: any = await _axiosInstance.get(uri);
    return res.data;
  } catch (e) {
    throw e;
  }
};
