// @ts-nocheck
import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./components/wrapper/snack";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./customStyles/scss/react-phoneInput2.scss";

const rootElement = document.getElementById("root");

const AppElement = (
  <GoogleOAuthProvider clientId="337705274204-gac97kappr7imkhg32ijarfdgv0jfp8o.apps.googleusercontent.com">
    <React.StrictMode>
      <SnackbarProvider hideIconVariant>
        <SnackbarUtilsConfigurator />
        <App />
      </SnackbarProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

if (rootElement.hasChildNodes()) {
  hydrate(AppElement, rootElement);
} else {
  render(AppElement, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
