import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";




export const  getPaysferBalance = async () => {
  let uri = '/payment/get-paysfer-balance';
  try {
      console.log('get-gift-card')
    const res = await axios.get(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    console.log(e)
    throw e;
  }
};

export const  redeemPaysferGiftCard = async (data:any) => {
    let uri = '/payment/redeem-paysfer-gift-card';
    try {
        console.log('redeem-paysfer-gift-card',data)
      const res = await axios.post(uri, data,{ headers: { "Authorization": `Bearer ${getAccessToken()}` } });
      return res.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  };
  
 