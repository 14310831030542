import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getProductsStart, updateOnlyProductsStart } from "redux/sellers";
import { addToWishListStart, removeFromWishListStart } from "redux/wishlist";
import {
  Box,
  Modal,
  Tooltip,
  Typography,
  TextField,
  Pagination,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { titelize } from "utils/titelize";
import CopyToClipboard from "react-copy-to-clipboard";
import snack from "components/wrapper/snack";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { getReviewBySellerId } from "redux/rating";
import Drawer from "@mui/material/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ClearButton,
  FilterContainer,
  FilterLabel,
  MobileViewFilterWrapper,
  CardContainer,
} from "./styledComponents";
import { Search } from "@material-ui/icons";
import { checkIsValidArray } from "utils";
import React from "react";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import ShopBanner from "./ShopBanner";
import ShopMetaTags from "../ShopList/ShopMetaTags";
import styled from 'styled-components';
import { followSeller, unfollowSeller, getFollowingStatus } from '../../../../api/followApi';
import SingleProductLayout from "./SingleProductView"

  // background-color: ${({ isFollowed }) => (isFollowed ? '#ffa41c' : '#f0c14b')};
  // border: 1px solid ${({ isFollowed }) => (isFollowed ? '#ffa41c' : '#a88734 #9c7e31 #846a29')};
const FollowButton = styled.button<{ isFollowed: boolean, disabled: boolean }>`
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  font-size: 10px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  border-radius: 3px;
  text-align: center;
  &:hover {
    background-color: ${({ isFollowed }) => (isFollowed ? '#e78a15' : '#ddb347')};
  }
`;

const ShopDetails = () => {
  const location: any = useLocation();
  const params = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const [page, setPage] = useState(1);
  const [tabValue, setTabValue] = useState("1");
  const [shareProductDetails, setShareProductDetails] = useState({
    id: "",
    title: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [shareModelOpen, setShareModelOpen] = useState(false);
  const [sellerBrandData, setSellerBrandData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const [filteredData, setFilteredData] = useState<{
    brand: any[];
    categories: any[];
  }>({ brand: [], categories: [] });
  const [openDropdown, setOpenDropdown] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<any>({});

  const [filter, setfilter] = useState<boolean>(false);

  const [smallScreenFilter, setSmallScreenFilter] = useState<string | false>(
    false
  );
  const user = useSelector((state: RootState) => state.user.user);
  const products = useSelector((state: RootState) => state.seller.products);
  const sellerDataLoading = useSelector(
    (state: RootState) => state.seller.isLoading
  );
  const parentCategoryDetails = useSelector(
    (state: RootState) => state.seller.products?.parentCategoryDetails
  );
  const seller = useSelector((state: RootState) => state.seller.seller);

  const sellerBrand = useSelector(
    (state: RootState) => state.sellerShopFilter.sellerFilters.brand
  );
  const reviews = useSelector(
    (state: RootState) => state.rating.sellerReview.review
  );
  const reviewProducts = useSelector(
    (state: RootState) => state.rating.sellerReview.product
  );
  const wishlist = useSelector((state: RootState) => state.wishlist.wishList);
  const accessToken: any = useSelector(
    (state: RootState) => state.user.accessToken
  );
  const Seller_Shop_Name = seller?.shopName || seller?.firstName || "";
  const shareUrl = `${window.location.origin}/product-page/${
    shareProductDetails.id
  }/${titelize(shareProductDetails.title)}`;

  const handleCategoryClick = (event: any, currentIndx: any) => {
    setOpenDropdown((prev: any) => ({
      ...prev,
      [currentIndx]: !prev[currentIndx],
    }));
    setAnchorEl((prev: any) => ({
      ...prev,
      [currentIndx]: !!prev[currentIndx] ? null : event.target,
    }));
  };
  const handleClose = (index: any) => {
    setOpenDropdown((prev: any) => ({ ...prev, [index]: false }));
    setAnchorEl((prev: any) => ({
      ...prev,
      [index]: null,
    }));
  };
  const changeCategoryName = (category?: number) => {
    dispatch(
      updateOnlyProductsStart({
        sellerId: params?.id,
        page,
        filter: {
          parentCategoryIdList: category,
        },
      })
    );
  };
  const renderSubCategories = (subCategories: any) => (
    <Grid
      container
      sx={{
        padding: "5px 10px",
      }}
    >
      <Grid item>
        {subCategories.map((subCategory: any, index: number) => {
          return (
            <Grid container key={subCategory.categoryId}>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() => changeCategoryName(subCategory.categoryId)}
                >
                  {subCategory.categoryName}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );

  // const handleAddToWishList = (item: any) => {
  //   if (!accessToken) {
  //     snack.error("Please Sign in to Continue");
  //     return;
  //   } else {
  //     const _product: any = {};
  //     _product.productId = item._id;
  //     _product.name = item.title;
  //     _product.price = Number(
  //       item.saleprice ? item.saleprice[0] : item.price[0]
  //     );
  //     _product.type = "wishlist";
  //     _product.total = Number(
  //       item.saleprice ? item.saleprice[0] : item.price[0]
  //     );
  //     dispatch(addToWishListStart(_product));
  //     snack.success("Item added to Wishlist");
  //   }
  // };
  // const handleRemoveToWishList = (item: any) => {
  //   if (!wishlist) {
  //     return;
  //   }
  //   wishlist.map((wishlistItem) => {
  //     if (wishlistItem["_id"] === item._id) {
  //       dispatch(removeFromWishListStart(wishlistItem["wishlistId"]));
  //       snack.error("Item removed from Wishlist");
  //     }
  //   });
  // };

  const handleInternalTextSearch = (searchData: string) => {
    const enteredValueByUser = (searchData || "").toLowerCase();
    dispatch(
      updateOnlyProductsStart({
        sellerId: params?.id,
        page,
        filter: {
          searchString: enteredValueByUser,
          categories: filteredData.categories,
        },
      })
    );
  };

  const handleSmallScreenFiltersExpand =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setSmallScreenFilter(isExpanded ? panel : false);
    };
  


  const handleChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };
  


  const checkFollowStatus = async () => {
    if (!user._id || !seller?._id) {
      console.log('Missing userId or seller._id');
      return;
    }
    try {
      console.log('Checking follow status for userId:', user._id, 'and sellerId:', seller._id);
      const followingList = await getFollowingStatus(user._id);
      console.log('Received followingList:', followingList);
      const isCurrentSellerFollowed = followingList.some((followedSeller: any) => followedSeller.sellerId._id === seller._id);
      console.log('Is current seller followed:', isCurrentSellerFollowed);
      setIsFollowing(isCurrentSellerFollowed);
    } catch (error) {
      console.error('Error checking follow status:', error);
    }
  };

  const handleFollowClick = async () => {
    if (!user._id || !seller?._id) {
      return;
    }
    setIsLoading(true);
    try {
      if (isFollowing) {
        await unfollowSeller(user._id, seller._id);
        setIsFollowing(false);
      } else {
        await followSeller(user._id, seller._id);
        setIsFollowing(true);
      }
      localStorage.setItem(`followStatus_${user._id}_${seller._id}`, JSON.stringify(!isFollowing));
    } catch (error: any) {
      console.error('Error updating follow status:', error);
      if (error.response?.data?.details === "User is already following this seller") {
        setIsFollowing(true);
      } else {
      }
    } finally {
      setIsLoading(false);
    }
  };

  
  useEffect(() => {
    setId(location?.state?.item?._id);
  }, [location]);

  useEffect(() => {
    if (params.id) {
      dispatch(getProductsStart({ sellerId: params?.id, page }));
      dispatch(getReviewBySellerId(params?.id));
    }
  }, [params?.id]);

  useEffect(() => {
    let brands: any[] = [];
    sellerBrand.forEach((brand: any) => {
      brands.push(brand);
    });
    setSellerBrandData(brands);
  }, [sellerBrand]);

  useEffect(() => {
    console.log('ShopBanner useEffect triggered');
    if (user._id && seller?._id) {
      checkFollowStatus();
    }
  }, [user._id, seller]);

  return (
    <div className="shopDetailsContainer">
      <ShopMetaTags seller={seller} />
      <ShopBanner seller={seller} userId={user._id} />

      <div className="row">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "10px",
          }}
          className="largeScreenFilters"
        >
          <div
            style={{
              width: "95%",
            }}
          >
            <Grid
              container
              // spacing={1}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <Grid item xs={9} sm={9}>
                <Grid container spacing={2}>
                  <Grid item>
                    {user._id && seller && seller.sellerId && (
                      <FollowButton 
                        onClick={handleFollowClick} 
                        isFollowed={isFollowing}
                        disabled={isLoading}
                      >
                        {isLoading ? 'Loading...' : (isFollowing ? 'Followed' : 'Follow')}
                      </FollowButton>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => changeCategoryName()}
                    >
                      Home
                    </Typography>
                  </Grid>
                  {checkIsValidArray(parentCategoryDetails)
                    ? parentCategoryDetails.map(
                        (category: any, index: number) => (
                          <Grid item key={category.categoryId}>
                            <Grid
                              container
                              direction={"row"}
                              alignItems={"center"}
                            >
                              <Grid item>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                  onClick={() =>
                                    changeCategoryName(category.categoryId)
                                  }
                                >
                                  {category.categoryName}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {category.subCategories &&
                                  category.subCategories.length > 0 && (
                                    <ExpandMoreIcon
                                      onClick={(e) =>
                                        handleCategoryClick(e, index)
                                      }
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                <Popper
                                  open={openDropdown?.[index]}
                                  anchorEl={anchorEl?.[index]}
                                  placement="bottom"
                                >
                                  <ClickAwayListener
                                    onClickAway={() => handleClose(index)}
                                  >
                                    <Paper>
                                      {renderSubCategories(
                                        category.subCategories
                                      )}
                                    </Paper>
                                  </ClickAwayListener>
                                </Popper>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      )
                    : null}
                </Grid>
              </Grid>

              <Grid item className="ShopFilter-Grid" xs={3} sm={3}>
                <TextField
                  fullWidth
                  defaultValue={searchValue}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: "40px",
                    },
                    startAdornment: (
                      <Search
                        style={{
                          opacity: 0.5,
                        }}
                      />
                    ),
                  }}
                  onChange={(e: any) => {
                    if (e.target.value == "" || e.target.value == null) {
                      handleInternalTextSearch(e.target.value);
                    }
                    setSearchValue(e.target.value);
                  }}
                  onKeyPress={(event: any) => {
                    if (event.key == "Enter") {
                      handleInternalTextSearch(searchValue);
                    }
                  }}
                  placeholder={`Search by ${
                    products.totalCount
                      ? `${products.totalCount} products`
                      : "products"
                  }`}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {/* Mobile View Filters */}
        <Drawer
          anchor={"bottom"}
          open={filter}
          onClose={() => setfilter(!filter)}
          className="drawer"
        >
          <MobileViewFilterWrapper>
            <FilterContainer>
              <FilterLabel>FILTERS</FilterLabel>
              <ClearButton onClick={() => setfilter(!filter)}>X</ClearButton>
            </FilterContainer>
            <div>
              <Accordion
                expanded={smallScreenFilter === "Categories"}
                onChange={handleSmallScreenFiltersExpand("Categories")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="Categories-content"
                  id="Categories-header"
                >
                  <p className="sellerHeaders">Categories</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="contentOverFlow">
                    <FormGroup></FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={smallScreenFilter === "Brand"}
                onChange={handleSmallScreenFiltersExpand("Brand")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="Brand-content"
                  id="Brand-header"
                >
                  <p className="sellerHeaders">Brand</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="contentOverFlow">
                    <FormGroup>
                      {sellerBrandData.map((item: any, index: number) => (
                        <FormControlLabel
                          key={index.toString()}
                          control={
                            <Checkbox
                              defaultChecked={
                                filteredData.brand.indexOf(item) >= 0
                                  ? true
                                  : false
                              }
                              onChange={(e: any, checked: any) => {
                                let data = [...sellerBrandData];
                                let data1 = [...filteredData.brand];
                                data.forEach((ele: any, index: number) => {
                                  if (item === ele) {
                                    // updating filter in filterData
                                    if (checked) {
                                      data1.push(item);
                                    } else {
                                      let i = data1.indexOf(item);
                                      data1.splice(i, 1);
                                    }
                                  }
                                });
                                setFilteredData({
                                  categories: filteredData.categories,
                                  brand: data1,
                                });
                                dispatch(
                                  updateOnlyProductsStart({
                                    sellerId: params?.id,
                                    page,
                                    filter: {
                                      categories: filteredData.categories,
                                      brand: data1,
                                    },
                                  })
                                );
                              }}
                            />
                          }
                          label={item}
                        />
                      ))}
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <br />
            <br />
          </MobileViewFilterWrapper>
        </Drawer>
        <p className="shops_filterButton" onClick={() => setfilter(true)}>
          FILTER
          <FontAwesomeIcon icon={faFilter} />
        </p>
        {/* Shop Details */}
        <div className={"col-lg-12 col-md-12 ShopDetailsWrapper"}>
          <div className={"shopDetailsContent"}>
            <Box sx={{ width: "100%", typography: "body1" }}>
              {/* 
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Shop" value="1" />
                    <Tab label="Reviews" value="3" />
                  </TabList>
                </Box>
                <TabPanel
                  value="1"
                  classes={{
                    root: "tabPanelRoot",
                  }}
                >
                  <> */}

              {checkIsValidArray(products.products) ? (
                <div className="row" style={{ marginBottom: "24px" }}>
                  {products.products.map((item: any, index: number) =>
                    <SingleProductLayout key={index} item={item} params={params} index={index} />
                  )}
                </div>
              ) : (
                <>
                  {!sellerDataLoading && (
                    <div style={{ textAlign: "center" }}>
                      <h4>
                        Product you searched for is not available in this shop.
                        Try searching in the main search bar above
                      </h4>
                    </div>
                  )}
                </>
              )}
              <div>
                <Pagination
                  onChange={(e: any, page: any) => {
                    dispatch(
                      updateOnlyProductsStart({
                        sellerId: params?.id,
                        page,
                        filter: filteredData,
                      })
                    );
                    window.scroll(0, 0);
                  }}
                  count={products.totalPages}
                />
              </div>
              <Modal
                open={shareModelOpen}
                onClose={() => {
                  setShareModelOpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "white",
                    borderRadius: "5px",
                    boxShadow: "24",
                    p: 4,
                    height: 240,
                    background: "white",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h3"
                  >
                    Share this listing
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      padding: "0.4rem",
                      alignSelf: "stretch",
                    }}
                  >
                    <p>Click on this icon to copy below link</p>
                    <CopyToClipboard
                      text={`${window.location.origin}/product-page/${
                        shareProductDetails.id
                      }/${titelize(shareProductDetails.title)}`}
                      onCopy={() => {
                        snack.success("Link copied to clipboard!");
                      }}
                    >
                      <Tooltip
                        title="Copy Below link"
                        style={{ marginRight: "5em" }}
                      >
                        <InsertLinkIcon
                          sx={{
                            opacity: 1,
                            cursor: "pointer",
                            transition: "1s",
                            stroke: "black",
                            strokeWidth: 1,
                            "&:hover": {
                              opacity: 1,
                              transform: "scale(1.05)",
                              transition: "1s",
                            },
                          }}
                        />
                      </Tooltip>
                    </CopyToClipboard>
                  </Box>
                  <Box
                    sx={{
                      width: 500,
                      maxWidth: "100%",
                    }}
                  >
                    <CopyToClipboard
                      text={`${window.location.origin}/product-page/${
                        shareProductDetails.id
                      }/${titelize(shareProductDetails.title)}`}
                      onCopy={() => {
                        snack.success("Link copied to clipboard!");
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Link"
                        sx={{ input: { cursor: "pointer" } }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        disabled={true}
                        defaultValue={shareUrl}
                      />
                    </CopyToClipboard>
                  </Box>
                  <div
                    style={{
                      background: "#0000",
                      height: "100vh",
                      width: "100%",
                      display: "flex",
                      gap: "0.5em",
                      justifyContent: "center",
                      marginTop: "1em",
                    }}
                  >
                    <FacebookShareButton url={shareUrl}>
                      <FacebookIcon size={40} round={true} />
                    </FacebookShareButton>

                    <WhatsappShareButton url={shareUrl}>
                      <WhatsappIcon size={40} round={true} />
                    </WhatsappShareButton>

                    <EmailShareButton url={shareUrl}>
                      <EmailIcon size={40} round={true} />
                    </EmailShareButton>

                    <RedditShareButton url={shareUrl}>
                      <RedditIcon size={40} round={true} />
                    </RedditShareButton>
                    <TwitterShareButton url={shareUrl}>
                      <TwitterIcon size={40} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton url={shareUrl}>
                      <LinkedinIcon size={40} round={true} />
                    </LinkedinShareButton>
                  </div>
                </Box>
              </Modal>
              {/* </>
                </TabPanel>
                <TabPanel value="3">
                  {reviews?.length > 0 ? (
                    <>
                      <CustomReviewSection
                        reviews={reviews}
                        product={reviewProducts}
                      />
                    </>
                  ) : null}
                </TabPanel>
              </TabContext>
             */}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopDetails;
