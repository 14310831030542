import { createSlice } from '@reduxjs/toolkit';
import { getAccessToken } from 'utils/auth';

interface State {
  isLoading: boolean;
  cartItems: any;
  selectedProduct: any;
  selectedCartItem:any;
  cartBanner: number;
  saveCartItem: any;
  error: boolean;
}

const initialState: State = {
  isLoading: true,
  selectedProduct: {},
  cartItems: [],
  selectedCartItem:[],
  cartBanner: 0,
  saveCartItem: [],
  error: false
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCartStart: (state: State, action) => {
      return({
      ...initialState,
      cartItems:[...initialState.cartItems,{selectedSize:action.payload.selectedSize ,selectedColor:action.payload.selectedColor}],
      isLoading: true
    })},
    addToCartSuccess: (state: State, action) => {
      let cartBanner = 0;
      if (getAccessToken()) {
        state.cartItems = action.payload.cartItems;

        for (let i = 0; i < state.cartItems.length; i++) {
          if (state.cartItems[i].saveForLater) {
            continue;
          }
          cartBanner += state.cartItems[i].cartQty;
        }

        state.cartBanner = cartBanner;
        state.isLoading = false;
      } else {
        state.cartItems = action.payload.cartItems;
        state.saveCartItem = action.payload.saveCartItem;

        for (let i = 0; i < state.saveCartItem.length; i++) {
          if (state.cartItems && state.cartItems[i] && state.cartItems[i].saveForLater) {
            continue;
          }
          cartBanner += state.saveCartItem[i].quantity;
        }
        state.cartBanner = cartBanner;
        state.isLoading = false;
      }
    },
    addToCartFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    updateSelectedCartItems: (state: State,action) => ({
      ...state,
      selectedCartItem:action.payload
    }),
    removeFromCartStart: (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    removeFromCartSuccess: (state: State, action) => ({
      ...state,
      isLoading: false
    }),
    removeFromCartFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    updateCartBanner: (state: State, action) => {
      state.cartBanner = action.payload.cartBanner - action.payload.item.quantity
    },
    getCartItemsStart: (state: State) => ({
      ...initialState,
      isLoading: true
    }),
    saveForLater: (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    clearCartData: (state: State) => ({
      ...state,
      cartItems: [],
      cartBanner: 0,
      isLoading: false,
      error: false
    })
  }
});

export const {
  addToCartStart,
  addToCartSuccess,
  addToCartFailure,
  removeFromCartStart,
  removeFromCartSuccess,
  updateSelectedCartItems,
  removeFromCartFailure,
  getCartItemsStart,
  clearCartData,
  saveForLater,
  updateCartBanner
} = cartSlice.actions;
export default cartSlice.reducer;
