import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// page number and limit

interface State {
  view: boolean;
}

const initialState: State = {
  view: false,
};

export const mobileSlice = createSlice({
  name: "mobileLogin",
  initialState,
  reducers: {
    updateMobileState: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        view: action.payload,
      };
    },
  },
});

export const { updateMobileState } = mobileSlice.actions;
export default mobileSlice.reducer;
