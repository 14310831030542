import { createSlice } from "@reduxjs/toolkit";
import { checkIsValidArray } from "utils";
// page number and limit

interface State {
  isLoading: boolean;
  error: boolean;
  categoryList: any;
  topCategoryList: any;
  selectedCategory: string;
  sellLikeItemLoading: boolean;
  sellLikeItemCategoryList: any;
}

const initialState: State = {
  isLoading: true,
  error: false,
  topCategoryList: [],
  categoryList: [],
  selectedCategory: "",
  sellLikeItemLoading: false,
  sellLikeItemCategoryList: {},
};

export const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    fetchCategoriesStart: () => ({
      ...initialState,
      isLoading: true,
    }),
    fetchCategoriesSuccess: (state: State, action) => ({
      ...state,
      categoryList: action && action.payload.categories.ResponseBody,
      topCategoryList: action && action.payload.topCategories.ResponseBody,
      isLoading: false,
    }),
    fetchCategoriesFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    fetchCategoriesList: (state, action) => ({
      ...state,
      isLoading: false,
      categoryList: action.payload,
    }),
    selectCatgory: (state, action) => ({
      ...state,
      selectedCategory: action.payload,
    }),
    fetchCategoriesStartForSellLikeItem: (state, action) => ({
      ...initialState,
      sellLikeItemLoading: true,
      sellLikeItemCategoryList: {
        ...(state?.sellLikeItemCategoryList || {}),
        finalUserSelectedCategory: {},
        categoryBreadCrum: "",
        currentCategoriesToDisplay: [],
        intialLoadFromUpdateProduct: true,
      },
    }),
    fetchCategoriesStartForSellLikeItemSuccess: (state, action) => ({
      ...state,
      sellLikeItemLoading: false,
      sellLikeItemCategoryList: action.payload,
    }),
    fetchChildCategoryStartForSellLikeItem: (state, action) => ({
      ...state,
      sellLikeItemLoading: true,
    }),
    categoryStateHandling: (state, action) => {
      return {
        ...state,
        sellLikeItemCategoryList: {
          ...(state?.sellLikeItemCategoryList || {}),
          categoryBreadCrum: action.payload?.updateBreadCrums || "",
          currentCategoriesToDisplay:
            ((state?.sellLikeItemCategoryList || {})?.basedOnCatergoryId ||
              {})?.[action.payload.categoryId || ""] ||
            (state?.sellLikeItemCategoryList || {})?.categories,
          finalUserSelectedCategory: {},
        },
      };
    },
    fetchChildCategoryStartForSellLikeItemSuccess: (state, action) => {
      const currentCategoriesToDisplay =
        (action.payload || {})?.basedOnCatergoryId || {};
      const currentCategoriesDetails =
        currentCategoriesToDisplay?.[action.payload.categoryId];
      let categoryBreadCrum =
        state?.sellLikeItemCategoryList?.categoryBreadCrum;
      let finalUserSelectedCategory = {};
      if (checkIsValidArray(currentCategoriesDetails)) {
        categoryBreadCrum =
          (state?.sellLikeItemCategoryList?.categoryBreadCrum || "") +
            "/" +
            (action.payload || {})?.categoryBreadCrum || "";
      } else {
        finalUserSelectedCategory = {
          categoryName: action.payload?.categoryName,
          categoryId: action.payload?.categoryId,
          useUps: action.payload.useUps,
        };
      }

      const finalCategoriesToDisplay = checkIsValidArray(
        currentCategoriesDetails
      )
        ? currentCategoriesDetails
        : (state.sellLikeItemCategoryList || {})?.currentCategoriesToDisplay ||
          [];
      return {
        ...state,
        sellLikeItemLoading: false,
        sellLikeItemCategoryList: {
          ...(state?.sellLikeItemCategoryList || {}),
          categoryBreadCrum,
          currentCategoriesToDisplay: finalCategoriesToDisplay,
          finalUserSelectedCategory,
          intialLoadFromUpdateProduct:
            action.payload?.intialLoadFromUpdateProduct,
          basedOnCatergoryId: {
            ...(((state || {})?.sellLikeItemCategoryList || {})
              ?.basedOnCatergoryId || {}),
            ...currentCategoriesToDisplay,
          },
        },
      };
    },
  },
});

export const {
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
  fetchCategoriesList,
  selectCatgory,
  fetchCategoriesStartForSellLikeItem,
  fetchChildCategoryStartForSellLikeItem,
  fetchChildCategoryStartForSellLikeItemSuccess,
  fetchCategoriesStartForSellLikeItemSuccess,
  categoryStateHandling,
} = categorySlice.actions;
export default categorySlice.reducer;
