import { titelize } from "./titelize";

export const openProductPage = (id:string, title:string, fromData:string = 'from-search'): void => {
    const formattedTitle = titelize(title);

    // Construct the URL with query parameters
    const url = new URL(`/product-page/${id}/${formattedTitle}`, window.location.origin);
    url.searchParams.set('src', fromData);

    // Open the new URL in a new tab
    window.open(url.toString(), '_blank');
}
