import { combineReducers } from "@reduxjs/toolkit";
import cartActions from "redux/cart";
import categoriesActions from "redux/categories";
import mobileLoginActions from "redux/mobileLogin";
import paymentActions from "redux/payment";
import userActions from "redux/user";
import wishlistActions from "redux/wishlist";
import addressActions from "redux/address";
import accountSettingActions from "redux/accountSettings";
import orderActions from "redux/order";
import productActions from "redux/product";
import kitchenActions from "redux/Home/kitchen";
import gamesActions from "redux/Home/games";
import electronicsActions from "redux/Home/electronics";
import bagsActions from "redux/Home/bags";
import valentineActions from "redux/Home/valentine";
import mobileActions from "redux/Home/mobile";
import travelActions from "redux/Home/travel";
import shoesActions from "redux/Home/shoes";
import stationaryActions from "redux/Home/stationary";
import reviewActions from "redux/rating";
import subscribeActions from "redux/newsLetter";
import subscriptionActions from "redux/subscription";
import sellersActions from "redux/sellers";
import sellersShopFilterAction from "redux/sellerShopFilter";
import dropdownCategoriesReducer from "redux/dropdownCategories";
import eGiftCardActions from "redux/eGiftCard";
import paysferAccountActions from "redux/paysferAccount";
import deliveryReducers from "redux/deliveryitem";

const rootReducer = combineReducers({
  cart: cartActions,
  categories: categoriesActions,
  mobileloginstate: mobileLoginActions,
  payment: paymentActions,
  user: userActions,
  wishlist: wishlistActions,
  address: addressActions,
  account: accountSettingActions,
  orders: orderActions,
  product: productActions,
  kitchen: kitchenActions,
  games: gamesActions,
  electronics: electronicsActions,
  bags: bagsActions,
  valentine: valentineActions,
  mobile: mobileActions,
  travel: travelActions,
  shoes: shoesActions,
  stationary: stationaryActions,
  rating: reviewActions,
  subscribe: subscribeActions,
  subscription: subscriptionActions,
  seller: sellersActions,
  sellerShopFilter: sellersShopFilterAction,
  dropdownCategories: dropdownCategoriesReducer,
  eGiftCard:eGiftCardActions,
  paysferAccount:paysferAccountActions,
  delivery: deliveryReducers,
});

export default rootReducer;
