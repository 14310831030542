import { createSlice } from "@reduxjs/toolkit";

interface State {
  mySubscriptions: Array<Object>;
  error: boolean;
  isLoading: boolean;
}

const initialState: State = {
  mySubscriptions: [],
  error: false,
  isLoading: false,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    getMySubscriptionsStart: (state: State, action) => ({
      ...initialState,
      error: false,
      isLoading: true,
    }),
    getMySubscriptionsSuccess: (state: State, action) => ({
      ...state,
      mySubscriptions: action.payload.mySubscriptions,
      isLoading: false,
    }),
    cancelSubscriptionStart: (state: State, action) => ({
      ...initialState,
      error: false,
      isLoading: true,
    }),
    updateMySubscriptionsStart: (state: State, action) => ({
      ...initialState,
      error: false,
      isLoading: true,
    }),
    getMySubscriptionsFailure: (state: State, action) => ({
      ...state,
      error: action.payload,
      isLoading: false,
    }),
  },
});

export const {
  getMySubscriptionsStart,
  getMySubscriptionsSuccess,
  getMySubscriptionsFailure,
  cancelSubscriptionStart,
  updateMySubscriptionsStart

} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
