import { Grid } from "@mui/material";
import { Link } from "@mui/material";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "redux/store";
import { titelize } from "utils/titelize";
import "./style.css";
import { openProductPage } from "utils/product";

const ThankYou = () => {
  const history = useHistory();

  const singlereviewItem = (reviewItem: any, index: number) => {
    return (
      <div className="cart-detail d-flex" key={index}>
        <div className="d-flex" style={{ flex: 1 }}>
          <div className="product-img">
            <img alt="productImage" src={reviewItem.productgroupimageurl[0]} loading="lazy"/>
          </div>

          <div className="product-detail-text">
            <div>
              <p className="product-name">
                <Link
                  onClick={() => {
                    const {_id, title} = reviewItem; 
                    openProductPage(_id, title)
                  }}
                >
                  {reviewItem.title}
                </Link>
              </p>

              <p className="product-text">{reviewItem.description}</p>
            </div>
            <div className="price-qty justify-content-between d-lg-none">
              <div>
                {reviewItem.saleprice === undefined ||
                reviewItem.saleprice === 0 ? (
                  <p>
                    {(Number(reviewItem.price) * reviewItem.cartQty).toFixed(2)}
                  </p>
                ) : (
                  <p>
                    {(
                      Number(reviewItem.saleprice) * reviewItem.cartQty
                    ).toFixed(2)}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="product-price">
            <p>
              <b>
                $
                {(Number(reviewItem.saleprice) * reviewItem.cartQty).toFixed(2)}
              </b>
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="thankyouwrapper">
         <Helmet>
          <script>{`${"gtag('event', 'conversion', {'send_to': 'AW-437158841/7uTbCM6h-pYYELmHutAB', 'transaction_id': ''})"}`}</script>
        </Helmet>
      
      <div style={{display:'flex'}}>
        <button className="thank_you_btn" onClick={() => history.push("/")}>
          Go Back to home page
        </button>
        <button className="thank_you_btn" onClick={() => history.replace("/my-orders")}>
          View my Orders
        </button>
      </div>  

      <div className="thankYouContainer">
        <div className="thankYouContainer__gifContainer">
          <div className="thankYouContainer__gifContainer--gif"></div>
        </div>

        <h2 className="thankYouContainer__heading">
          Purchase of your order was successfully completed
        </h2>

        <p className="thankYouContainer__msgPara">
          Please login to My Orders to check the status of your order
        </p>
      </div>
    </div>
  );
};

export default ThankYou;
