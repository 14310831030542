//Define type of route
export const USER_ROUTE = "/users";
export const PRODUCT_CATEGORY = "/product-category";
export const PRODUCT = "/product";

const ROUTE_PATHS = {
  HOME: "/",
  BUYER_REGISTER: `${USER_ROUTE}/buyer/register`,
  SELLER_REGISTER: `${USER_ROUTE}/seller/register`,
  SIGN_IN: "/signin",
  VERIFY_TOKEN: "/verify-token/:token",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD_OTP: "/reset-password",
  RESET_PASSWORD_EMAIL: "/reset-password/email/:token",
  RESEND_EMAIL: "/resend-email",
  BLOG: "/blog",
  CART: "/cart",
  WISHLIST: "/wishlist",
  SUPPORT: "/support",
  CHECKOUT: "/checkout",
  GIFTCARD: "/paysfer-gift-card",
  AFFILIATE_REGISTER: "/affiliate-register",
  AFFILIATE_LOGIN: "/affiliate-login",
  CONTACT_US: "/contact-us",
  ABOUT_US: "/about-us",
  AFFILIATE_HOME: "/affiliate-home",
  SPECIAL_OFFER: "/special-offer",
  EXCLUSIVE_COLLECTION: "/exclusive-collection",
  BEST_OFFERS: "/best-offers",
  RATING_REVIEW: "/create-review",
  PRODUCT_PAGE: "/product-page",
  PRODUCT_CATEGORY_ITEM: "/product-page/:id/:title",
  SELL_LIKE_ITEM: "/sell-like-item",
  CATEGORIES: "/categories",
  EXPLORE_PRODUCTS: "/explore-products/:category",
  TRACK_ORDER: "/track-order",
  MY_ORDERS: "/my-orders",
  MY_GIFT_CARD_ORDERS: "/my-gift-card-orders",
  MY_SALES: "/my-sales",
  ACCOUNT_SETTINGS: "/account-settings",
  ACCOUNT_SETTING_TYPE: "/account-settings/:setting",
  PAYMENT_PROCESS: "/payment-process/:step",
  EDIT_ADDRESS: "/address/edit",
  PAYPAL_CHECKOUT: "/payment/paypal",
  STRIPE_CHECKOUT: "/payment/stripe",
  PAYMENT_STATUS: "/payment/status/:type",
  STRIPE_PAYMENT_STATUS: "/stripe-payment/status",
  DIRECT_SELLERS_ONBOARDING: "/seller/onboarding/:step",
  SEARCH_RESULTS: "/search-results",
  ELECTRONICS_SUBSHOP: "/electronics",
  SHOES_SUBSHOP: "/footwear",
  JEWELRY_SUBSHOP: "/jewelry",
  AUTO_SUBSHOP: "/auto",
  HOME_AND_GARDEN_SUBSHOP: "/home-garden",
  REFURBISHED_SUBSHOP: "/refurbished",
  HEALTH_AND_BEAUTY_SUBSHOP: "/health-beauty",
  FASHION_SUBSHOP: "/fashion",
  PERFUMES_SUBSHOP: "/perfumes",
  CD_SUBSHOP: "/electronics/cd",
  SUBSHOP: "/shop/:parentCategory",
  ADDRESSES: "/addresses",
  CREATE_ADDRESS: "/create-address",
  ADDRESS_EDIT: "/edit-address",
  VERIFY_EMAIL_OTP: "/verify-email-otp",
  PAYMENT_METHODS: "/payment-methods",
  MY_SUBSCRIPTION: "/my-subscriptions",
  PRIVACY_POLICY: "/privacy-policy",
  COOKIE_POLICY: "/cookie-policy",
  RETURN_POLICY: "/return-policy",
  THANKYOU: "/thankyou",
  SHOPLIST: "/shops",
  BLOGS: "/blogs",
  BLOGPAGE: "/blogs/:id",
  SHOP: "/shops/:id",
  PAYSFER_BALANCE: "/paysfer-balance",
  PAYSFER_BALANCE_CODE: "/paysfer-balance/:code",
};

export default ROUTE_PATHS;
