import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
 accountBalance:string;
 updateBalance:boolean;
 
}

const initialState: State = {
  isLoading: true,
  error : false,
  accountBalance:'0',
  updateBalance:false
};

export const paysferAccountSlice = createSlice({
  name: "paysferAccount",
  initialState,
  reducers: {
    getAccountBalanceStart: (state: State) => ({
      ...initialState,
      isLoading: true,
      updateBalance:false
    }),
    getAccountBalanceSuccess: (state: State, action: any) => {
      if (action.payload.length > 0)
        return {
          ...state,
          isLoading: false,
          accountBalance: action.payload[0].balance,
          updateBalance:false
        };
      else
        return {
          ...state,
          isLoading: false,
          accountBalance: 0,
          updateBalance:false
        };
    },
    redeemEgiftCardStart: (state: State, action) => ({
      ...initialState,
      isLoading: true,
      updateBalance:false
    }),
    redeemEgiftCardSuccess: (state: State) => ({
      ...state,
      isLoading: false,
      updateBalance:true
    }),
  },
});

export const {
    getAccountBalanceStart,
    getAccountBalanceSuccess,
    redeemEgiftCardStart,
    redeemEgiftCardSuccess
} = paysferAccountSlice.actions;
export default paysferAccountSlice.reducer;
