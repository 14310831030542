import {_axiosInstance} from "api/axiosController";

export const getstationaryProducts = async (query: number) => {
  let uri = `/search/v1/get?query=stationary`;
  try {
    const res:any = await _axiosInstance.get(uri);
    console.log ({stationary : res })
    return res.data;
  } catch (e) {
    throw e;
  }
};

