import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  mobile?: any;
  error?: boolean;
  msg?: string
}

const initialState: State = {
  isLoading: false,
  mobile: [],
  error: false,
  msg: ''
};

export const mobileSlice = createSlice({
  name: 'homepage-section-mobile',
  initialState,
  reducers: {
    getmobileProductsStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    getmobileProductsSuccess: (state: State, action) => ({
      ...state,
      mobile: action.payload.result
    }),
    getmobileProductsFailure: (state: State, action) => ({
      ...state,
      error: action.payload.error,
      msg: action.payload.msg
    })
  }
});

export const {
  getmobileProductsStart,
  getmobileProductsSuccess,
  getmobileProductsFailure
} = mobileSlice.actions;
export default mobileSlice.reducer;
