import React, { useEffect, useState, Fragment } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { getSinglePrice } from "../Product/displayPrice";
import snack from "components/wrapper/snack";
import { openProductPage } from "utils/product";
import sendEventAndConversion from "api/algoliaTracker";
import { RootState } from "redux/store";
import { addToCartStart } from "redux/cart";


const SingleProductLayout = ({item, index, params}:{item: any, index: number, params: any}) => {
    const dispatch = useDispatch();
    const [qty, setQty] = useState(1);
    const [maxQty, setMaxQty] = React.useState(0);
    const [size, setSize] = useState<string>("");
    const [color, setColor] = useState<string>("");
    const wishlist = useSelector((state: RootState) => state.wishlist.wishList);
    const saveCartItem: any = useSelector(
        (state: RootState) => state.cart.saveCartItem
    );
    const location: any = useLocation();
    const accessToken: any = useSelector(
        (state: RootState) => state.user.accessToken
    );
    let cartItems: any = useSelector((state: RootState) => state.cart.cartItems);
    const handleAddToCartForNonLoggedInUser = () => {
      
      const _product: any = {};
      if (
        item.Quantity[0] === undefined ||
        item.Quantity === undefined ||
        item.Quantity[0] === "0" ||
        item.Quantity[0] <= 0
        ) {
        snack.error("Product out of stock");
        return;
      }
      if (!saveCartItem.length) {
        _product.quantity = qty;
      } else {
        const cartItem = saveCartItem.find(
            (item: any) => item.productId === item.id
        );
        if (cartItem) {
            if (
            Number(cartItem.quantity) === maxQty &&
            (qty === maxQty || qty > maxQty)
            ) {
            _product.quantity = maxQty;
            }

            if (Number(cartItem.quantity) <= maxQty && qty <= maxQty) {
            if (Number(cartItem.quantity) + qty >= maxQty) {
                _product.quantity = maxQty;
            }
            if (Number(cartItem.quantity) + qty < maxQty) {
                _product.quantity = Number(cartItem.quantity) + qty;
            }
            }
        } else {
            _product.quantity = qty >= 1 && qty <= maxQty ? qty : 1;
        }
      }

        _product.productId = item.id
        ? item.id
        : item._id;
        _product.name = item.title;
        _product.price = Number(
        item.saleprice[0]
            ? item.saleprice[0]
            : item.price[0]
        );
        _product.type = "cart";

        _product.productSellerSKU = item.SellerSKU[0];
        const selectedItem = cartItems.filter(
        (item: any, index: number) => item._id === item._id
        );

        if (selectedItem.length < 1) {
        _product.total =
            Number(
            item.saleprice[0]
                ? item.saleprice[0]
                : item.price[0]
            ) * _product.quantity;
        } else {
        _product.total =
            Number(
            item.saleprice[0]
                ? item.saleprice[0]
                : item.price[0]
            ) + selectedItem[0].total;
        }
        if (selectedItem && selectedItem[0] && selectedItem[0].selectedSize) {
          _product.selectedSize = [...selectedItem[0].selectedSize, size];
        } else {
          _product.selectedSize = [size];
        }

        if (selectedItem && selectedItem[0] && selectedItem[0].selectedColor) {
        _product.selectedColor = [...selectedItem[0].selectedColor, color];
        } else {
        _product.selectedColor = [color];
        }

        dispatch(addToCartStart({ _product, product: item }));
        snack.success("Item added in cart");
    };

    const handleAddToCartForLoggedInUser = () => {
        if (
          item.Quantity[0] === undefined ||
          item.Quantity === undefined ||
          item.Quantity[0] === "0" ||
          item.Quantity[0] <= 0
        ) {
          snack.error("Product out of stock");
          return;
        }
        const _product: any = {};
        _product.quantity = 1;
        const selectedItem = cartItems.filter(
        (item: any, index: number) => item._id === item._id
        );
        if (selectedItem.length < 1) {
        _product.total =
            Number(
            item.saleprice[0]
                ? item.saleprice[0]
                : item.price[0]
            ) * _product.quantity;
        } else {
        _product.total =
            Number(
            item.saleprice[0]
                ? item.saleprice[0]
                : item.price[0]
            ) + selectedItem[0].total;
        }
        if (selectedItem && selectedItem[0] && selectedItem[0].selectedSize) {
          _product.selectedSize = [...selectedItem[0].selectedSize, size];
        } else {
          _product.selectedSize = [size];
        }

        if (selectedItem && selectedItem[0] && selectedItem[0].selectedColor) {
          _product.selectedColor = [...selectedItem[0].selectedColor, color];
        } else {
          _product.selectedColor = [color];
        }

        _product.productId = item.id
        ? item.id
        : item._id;
        _product.name = item.title;
        // _product.price = Number(props.product.price[index]);
        _product.type = "cart";

        // _product.total =
        //   Number(
        //     props.product.saleprice[index]
        //       ? props.product.saleprice[index]
        //       : props.product.price[index]
        //   ) * _product.quantity;
        _product.productSellerSKU = item.SellerSKU[0];

        dispatch(addToCartStart({ _product }));
        snack.success("Item added in cart");
    };
    const handleAddToCart = () => {
        if (!accessToken) {
            // snack.error ('Please signin to continue');
            // return;
            handleAddToCartForNonLoggedInUser();
        } else {
            handleAddToCartForLoggedInUser();
        }

        // send events to algolia
        if (location.state && location.state.item?.objectID && location.state.item?.__queryID) {
            // aa("addedToCartObjectIDsAfterSearch", {
            //   eventName: "Product Added To Cart",
            //   index: "paysfer",
            //   queryID: `${location.state.item.__queryID}`,
            //   objectIDs: [location.state.item.objectID],
            // });
            const data = {
            eventName: "Product Added To Cart",
            index: "paysfer",
            queryID: `${location.state.item.__queryID}`,
            objectIDs: [location.state.item.objectID],
            };

            sendEventAndConversion(data);
            //axios.post("http://localhost:4000/api/v1/algolia/track", data);
        }

    };
    let isWishlist = false;

    if (wishlist && wishlist.length > 0) {
      wishlist.forEach((ele) => {
        if (ele["_id"] === item["_id"]) {
          isWishlist = true;
        }
      });
    }

    const getBookProductDetails = (product: any)=>{
      if(product?.meta?.[0]?.type === "Book"){
        const productDetailsUpdate = []
        if (product?.meta?.[0]?.author){
          const authorName = product.meta[0].author.split(" ").reverse().join(" ").replace(/,$/, "");
          productDetailsUpdate.push(<h5>by {authorName}</h5>)
        }
        // if (product?.meta?.[0]?.bookType){
        //   productDetailsUpdate.push(<h6>{product?.meta?.[0]?.bookType}</h6>)
        // }
        return productDetailsUpdate
      }
      else {
        return []
      }
    }
    const bookProductDetails = getBookProductDetails(item);
    const handleClick = (item: any) => {
        const { _id, title } = item;
        openProductPage(_id, title);
    };
    return (
      <>
        {/* Mobile View */}
        <div className="hideSingleProductLayoutForMobileView" key={item._id}>
          <Box className="SingleProductLayoutForMobileView">
            <CardMedia
              image={item.productgroupimageurl1}
              title={item.title}
              sx={{ width: 200 }}
              onClick={() => handleClick(item)}
            />
            <CardContent>
              <Typography
                onClick={() => handleClick(item)}
                sx={{ fontWeight: 400 }}
                gutterBottom
                component="div"
              >
                {item.title}
              </Typography>
              {bookProductDetails.map((item: any) => (
                 item
              ))}
              { params?.id != "0592de2a-815c-4929-a521-82301b246cce" && (
                <Typography gutterBottom component="div">
                  ${getSinglePrice(item)}&#160;&#160;
                  {item.Quantity === undefined ||
                  item.Quantity[0] === undefined ||
                  item.Quantity[0] === "0" ||
                  item.Quantity[0] <= 0
                    ? "Out of Stock"
                    : "Available in Stock"}
                </Typography>
              )}
              <Typography variant="body2" color="text.secondary">
                {(item.shipping || "").includes("Free") ? (
                  <Fragment>
                    Free Shipping <br />
                  </Fragment>
                ) : null}
                Free returns1
              </Typography>
              <button
                    disabled={
                      item.Quantity === undefined ||
                      item.Quantity[0] === "0"
                        ? true
                        : false
                    }
                    style={{ marginTop : "15px", borderRadius: "10px", background:"#000000", fontSize: "12px" }}
                    className="btn btn-secondary"
                    onClick={handleAddToCart}
                  >
                    Add to Cart
                </button>
            </CardContent>
          </Box>
          <hr />
        </div>
        {/* Desktop View */}

        <div
          key={item.toString()}
          className="col-md-4 col-lg-3 SingleProductLayoutForDesktop"
        >
          <Card
            sx={{
              maxWidth: 345,
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "10px",
              transition: "1s",
              "&:hover": {
                cursor: "pointer",
                transform: "scale(1.05)",
                transition: "1s",
              },
            }}
          >
            <CardMedia
              sx={{ height: 250 }}
              image={item.productgroupimageurl1}
              title={item.title}
              onClick={() => handleClick(item)}
            />
            <CardContent sx={{ height:`${params?.id != "0592de2a-815c-4929-a521-82301b246cce" &&params?.id != "aa15f57f-06f8-4f22-a0eb-47056630fefc" ?"200px":"100px"}`, fontSize: "15px" }}>
              <h3 className="cartTitle" onClick={() => handleClick(item)}>
                {item.title}
                </h3>
              
              {bookProductDetails.map((item: any) => (
                 item
              ))}

            { params?.id != "0592de2a-815c-4929-a521-82301b246cce" && (
              <Typography gutterBottom component="div">
                ${getSinglePrice(item)}&#160;&#160;
                {item.Quantity === undefined ||
                item.Quantity[0] === undefined ||
                item.Quantity[0] === "0" ||
                item.Quantity[0] <= 0
                  ? "Out of Stock"
                  : "Available in Stock"}
              </Typography>
            )}

              {params?.id != "0592de2a-815c-4929-a521-82301b246cce" && params?.id != "aa15f57f-06f8-4f22-a0eb-47056630fefc" && (
                <Typography variant="body2" color="text.secondary">
                  {(item.shipping || "").includes("Free") ? (
                    <Fragment>
                      Free Shipping <br />
                    </Fragment>
                  ) : null}
                  {
                    params?.id === "29658816" ? (
                      <Fragment>
                        No returns 
                      </Fragment>
                    ): <Fragment>Free returns</Fragment>
                  }
                </Typography>
              )}
            </CardContent>
            <CardActions>
              <button
                    disabled={
                      item.Quantity === undefined ||
                      item.Quantity[0] === "0"
                        ? true
                        : false
                    }
                    style={{ width: "300px", marginTop : "15px", borderRadius: "10px", background:"#000000", fontSize: "12px" }}
                    className="btn btn-secondary"
                    onClick={()=> handleAddToCart()}
                  >
                    Add to Cart
                  </button>
              {/* <div className="cardActionsContainerForShopDetails">
                {isWishlist ? (
                  <FavoriteIcon
                    style={{ width: "18px", color: "#EF5051" }}
                    onClick={() => handleRemoveToWishList(item)}
                  />
                ) : (
                  <FavoriteBorderOutlinedIcon
                    style={{ width: "18px", color: "#EF5051" }}
                    onClick={() => handleAddToWishList(item)}
                    sx={{
                      opacity: 0.6,
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  />
                )}
                &nbsp;&nbsp;
                <ShareIcon
                  sx={{
                    opacity: 0.6,
                    "&:hover": {
                      opacity: 1,
                    },
                    width: "18px",
                    color: "#EF5051",
                  }}
                  onClick={() => {
                    setShareProductDetails({
                      id: item["_id"],
                      title: item.title,
                    });
                    setShareModelOpen(true);
                  }}
                />
              </div> */}
            </CardActions>
          </Card>
        </div>
      </>
    );
  };

  export default SingleProductLayout