import snack from "components/wrapper/snack";
import { call, put, takeLatest } from "redux-saga/effects";
import { subscribeUserFailure, subscribeUserStart, subscribeUserSuccess } from "redux/newsLetter";
import { subscribeNewLetter } from "redux/services/user";

function* SubscribeSagaWatcher() {
    yield takeLatest(
        [
            subscribeUserStart.type,
            subscribeUserFailure.type
        ],
        subscriptionWorker,
    );
}

function* subscriptionWorker(action: any): any {
    try {
        switch (action.type) {
            // eslint-disable-next-line no-lone-blocks
            case subscribeUserStart.type: {
                yield call (subscribeNewLetter, action.payload);  
                yield put (subscribeUserSuccess ({userSubscribed : true}));   
                snack.success ('You have succesfully subscribed to Paysfer');     
            }
                break;

            default: console.error('invalid case');
                break;

        }


    } catch (err: any) {
        console.error(`Error occuring while calling an action ${action.type}`, err);

        if (action.type === subscribeUserStart.type) {
            if (err.response?.data?.message === "User is already subscribed") {
                snack.warning ("You have already subscribed to our news letter");  
            }   
        }
    }
}

export default SubscribeSagaWatcher;
