import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  bags?: any;
  error?: boolean;
  msg?: string
}

const initialState: State = {
  isLoading: false,
  bags: [],
  error: false,
  msg: ''
};

export const bagsSlice = createSlice({
  name: 'homepage-section-bags',
  initialState,
  reducers: {
    getbagsProductsStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    getbagsProductsSuccess: (state: State, action) => ({
      ...state,
      bags: action.payload.result
    }),
    getbagsProductsFailure: (state: State, action) => ({
      ...state,
      error: action.payload.error,
      msg: action.payload.msg
    })
  }
});

export const {
  getbagsProductsStart,
  getbagsProductsSuccess,
  getbagsProductsFailure
} = bagsSlice.actions;
export default bagsSlice.reducer;
