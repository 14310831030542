import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  games?: any;
  error?: boolean;
  msg?: string
}

const initialState: State = {
  isLoading: false,
  games: [],
  error: false,
  msg: ''
};

export const gamesSlice = createSlice({
  name: 'homepage-section-games',
  initialState,
  reducers: {
    getgamesProductsStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    getgamesProductsSuccess: (state: State, action) => ({
      ...state,
      games: action.payload.result
    }),
    getgamesProductsFailure: (state: State, action) => ({
      ...state,
      error: action.payload.error,
      msg: action.payload.msg
    })
  }
});

export const {
  getgamesProductsStart,
  getgamesProductsSuccess,
  getgamesProductsFailure
} = gamesSlice.actions;
export default gamesSlice.reducer;
