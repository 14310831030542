// @ts-nocheck
import React, { useState } from "react";
import {
  TextField,
  Link,
  CircularProgress
} from "@mui/material";
import { FirebaseTypes } from "redux/services/fbApi";
import { SignInUser } from "types";
import Snack from "components/wrapper/snack";
import { sendOTPWithFb } from "redux/services/fbApi";
import { useHistory } from "react-router-dom";
import {
  useEffect
} from "react";
import { loginStart, sendOTPUsingFirebaseStart } from "redux/user";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import snack from "components/wrapper/snack";

interface InputProps {
  user: SignInUser;
}

declare global {
  interface Window {
    confirmationResult?: FirebaseTypes.auth.ConfirmationResult;
  }
}

const VerifyOTP: React.FC<InputProps> = ({ user }) => {
  const [otp, setOtp] = useState("");
  const appVerifier = useSelector((state: RootState) => state.user.appVerifier);
  const isLoading = useSelector((state: RootState) => state.user.isLoading);
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    dispatch(sendOTPUsingFirebaseStart({
      phoneNumber: user.phoneNumber,
      countryCode: user.countryCode
    }));
  }, []);

  const verifyOTP = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (window.confirmationResult) {
      window.confirmationResult
        .confirm(otp)
        .then(async () => {
          dispatch(loginStart({ user: { ...user, is2FAVerified: true }, history }));
        })
        .catch((e: any) => {
          console.error(e);
          Snack.error("Invalid OTP");
        });
    } else {
      window.location.reload();
    }
  };

  const resendOTP = async () => {
    if (user.phoneNumber) {
      const result = await sendOTPWithFb({
        phoneNumber: user.countryCode + user.phoneNumber,
        containerName: "recaptcha-container",
        appVerifier
      });
      if (!result) {
        snack.error("Unable to send OTP");
      } else {
        snack.success('An OTP has been re-sent to your mobile no.');
      }
    } else {
      snack.error("Unable to send OTP");
    }
  };

  return (
    <section className="auth-section">
      <div className="container">
        <div className="auth-form-wrap">
          <form onSubmit={verifyOTP}>
            <div className="form-group">
              <label >Enter OTP</label>
              <TextField
                className={'mui-text-field'}
                color='primary'
                name="verifyOTP"
                variant="outlined"
                required
                type="number"
                fullWidth
                id="verifyOTP"
                autoFocus
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <div id="recaptcha-container" />
            <div className="auth-btn-wrap">
              <button disabled={isLoading ? true : false} type="submit" className="btn btn-primary">
                {isLoading ? (<CircularProgress />) : (<>Verify OTP</>)}</button>
              {isLoading ? null : (<Link onClick={resendOTP}>Resend</Link>)}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default VerifyOTP;
