import { createSlice } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  error: boolean;
  products: any;
  product: {};
  image: object[];
  allProductsBasedOnFilter: any[];
  isfilterProductsAreLoading: boolean;
  filteredProductToListAnItem: any;
  listAnItemWithProduct: any;
  productUnderCreation: boolean;
  productCreationInfo: any;
  isMySalesIsLoading: boolean;
  activeSalesDetails: any;
  soldSalesDetails: any;
}

const initialState: State = {
  isLoading: true,
  error: false,
  products: [],
  product: {},
  image: [],
  isfilterProductsAreLoading: false,
  allProductsBasedOnFilter: [],
  filteredProductToListAnItem: {},
  listAnItemWithProduct: {},
  productUnderCreation: false,
  productCreationInfo: {},
  isMySalesIsLoading: false,
  activeSalesDetails: {},
  soldSalesDetails: {},
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    searchDataStart: (state: State, action) => ({
      ...initialState,
      isLoading: true,
    }),
    searchDataSuccess: (state: State, action) => ({
      ...state,
      isLoading: false,
      products: action.payload.products,
    }),
    searchDataFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    getProductsStartBasedOnFilters: (state: State, action) => ({
      ...initialState,
      isLoading: true,
    }),
    getProduct: (state: State, action) => ({
      ...initialState,
      isLoading: true,
    }),
    getProductSuccess: (state: State, action) => ({
      ...initialState,
      isLoading: false,
      product: action.payload.product,
    }),
    getProductFailure: (state: State, action) => ({
      ...initialState,
      isLoading: true,
    }),
    getImageStart: (state: State, action) => ({
      ...initialState,
      isLoading: true,
    }),
    getImageSuccess: (state: State, action) => ({
      ...initialState,
      isLoading: false,
      image: action.payload.image,
    }),
    getImageFailure: (state: State, action) => ({
      ...initialState,
      isLoading: true,
    }),
    getAllProductsBasedOnFiltersStart: (state: State, action) => ({
      ...initialState,
      isfilterProductsAreLoading: true,
    }),
    getAllProductsBasedOnFiltersSuccess: (state: State, action) => {
      return {
        ...initialState,
        isfilterProductsAreLoading: false,
        allProductsBasedOnFilter: action.payload.allProducts,
      };
    },
    setUserSelectedProductToListAnItem: (state: State, action) => ({
      ...initialState,
      filteredProductToListAnItem: action.payload,
    }),
    listAnItemWithProduct: (state: State, action) => ({
      ...initialState,
      listAnItemWithProduct: action.payload,
    }),
    getProductFromSellLikeItem: (state: State, action) => ({
      ...initialState,
    }),
    createASingleProductStart: (state: State, action) => ({
      ...state,
      productUnderCreation: true,
    }),
    updateSingleProductStart: (state: State, action) => ({
      ...state,
      productUnderCreation: true,
    }),
    deleteSingleProductStart: (state: State, action) => ({
      ...state,
    }),
    updateSingleProductSuccess: (state: State, action) => ({
      ...state,
      productUnderCreation: false,
    }),
    createASingleProductSuccess: (state: State, action) => ({
      ...initialState,
      productUnderCreation: false,
    }),
    getActiveSellLikeItemProductListStart: (state: State, action) => ({
      ...initialState,
      isMySalesIsLoading: true,
    }),
    getActiveSellLikeItemProductListSuccess: (state: State, action) => ({
      ...initialState,
      isMySalesIsLoading: false,
      activeSalesDetails: action.payload,
    }),
    getSoldSellItemsStart: (state: State, action) => ({
      ...initialState,
      isMySalesIsLoading: true,
    }),
    getSoldSellItemsSuccess: (state: State, action) => ({
      ...initialState,
      soldSalesDetails: action.payload,
      isMySalesIsLoading: false,
    }),
    getSoldSellItemsError: (state: State, action) => ({
      ...initialState,
      isMySalesIsLoading: false,
      soldSalesDetails: action.payload,
    }),
  },
});

export const {
  searchDataStart,
  searchDataSuccess,
  searchDataFailure,
  getProductsStartBasedOnFilters,
  getProduct,
  getProductSuccess,
  getProductFailure,
  getImageStart,
  getImageSuccess,
  getImageFailure,
  getAllProductsBasedOnFiltersStart,
  getAllProductsBasedOnFiltersSuccess,
  setUserSelectedProductToListAnItem,
  listAnItemWithProduct,
  getProductFromSellLikeItem,
  createASingleProductStart,
  createASingleProductSuccess,
  getActiveSellLikeItemProductListStart,
  getActiveSellLikeItemProductListSuccess,
  updateSingleProductStart,
  deleteSingleProductStart,
  updateSingleProductSuccess,
  getSoldSellItemsStart,
  getSoldSellItemsSuccess,
  getSoldSellItemsError,
} = productSlice.actions;
export default productSlice.reducer;
