import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  userSubscribed: boolean;
  subscribedUsers: any
}

const initialState: State = {
  isLoading: true,
  error : false,
  userSubscribed: false,
  subscribedUsers: []
};

export const subscribeSlice = createSlice({
  name: 'subscribe',
  initialState,
  reducers: {
    subscribeUserStart: (state: State, action) => ({
      ...initialState,
      isLoading: true
    }),
    subscribeUserSuccess: (state: State, action) => ({
      ...state,
      isLoading : false,
      userSubscribed: true
    }),
    subscribeUserFailure: (state: State) => ({
      ...state,
      error: true,
      isLoading: false,
    }),
    getSubscribedUsers: (state:State, action) => ({
      ...initialState,
      subscribedUsers : action.payload.subscribedUsers
    })
  }
});

export const {
   subscribeUserStart,
   subscribeUserSuccess,
   subscribeUserFailure,
   getSubscribedUsers
} = subscribeSlice.actions;
export default subscribeSlice.reducer;
