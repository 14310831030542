import { call, put, takeLatest } from "redux-saga/effects";
import {
  createEgiftCardStart,
  createEgiftCardSuccess,
  createGiftCardOrderStart,
  getGiftCardOrderStart,
  getGiftCardOrderSuccess,
  sendEgiftCardStart,
} from "redux/eGiftCard";
import {
  createGiftCardOrder,
  geteGiftCardStripeCheckoutUrl,
  getGiftCardOrder,
  sendGiftCardService,
} from "redux/services/eGiftCard";
import Snack from "components/wrapper/snack";

function* eGiftCardSagaWatcher() {
  yield takeLatest(
    [
      createEgiftCardStart.type,
      createGiftCardOrderStart.type,
      getGiftCardOrderStart.type,
      sendEgiftCardStart.type
    ],
    eGiftCardWorker
  );
}

function* eGiftCardWorker(action: any): any {
  try {
    switch (action.type) {
      case createEgiftCardStart.type:
        {
          const response: any = yield call(
            geteGiftCardStripeCheckoutUrl,
            action.payload
          );
          console.log("response", response);

          yield put(createEgiftCardSuccess(response.ResponseBody));
        }
        break;
      case createGiftCardOrderStart.type:
        {
          const response: any = yield call(createGiftCardOrder, action.payload);
          console.log(
            "createGiftCardOrdercreateGiftCardOrderresponse",
            response
          );

          // yield put (createEgiftCardSuccess(response.ResponseBody))
        }
        break;
        case sendEgiftCardStart.type:
          try{
          const response: any = yield call(sendGiftCardService, action.payload);
          Snack.success('Email sent!');
          if(response?.ResponseBody){
            yield put (getGiftCardOrderSuccess(response.ResponseBody))
          }
          else{
            yield put (getGiftCardOrderSuccess([]))
          }
        }catch(e){
          Snack.error('Email sent Failed!');
        }
          // yield put (createEgiftCardSuccess(response.ResponseBody))
        break;
      case getGiftCardOrderStart.type:
        {
          const response: any = yield call(getGiftCardOrder);
          console.log(
            "getGiftCardOrderStartgetGiftCardOrderStartgetGiftCardOrderStart",
            response
          );
          if(response?.ResponseBody){
            yield put (getGiftCardOrderSuccess(response.ResponseBody))

          }
          else{
            yield put (getGiftCardOrderSuccess([]))
          }
        }
        break;

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
  }
}

export default eGiftCardSagaWatcher;
