import styled from "styled-components";
export const ClearButton = styled.div`
  color: #76a9fa;
  cursor: pointer;
  font-size: 23px;
  margin-bottom: 0.5em;
  font-weight: bold;
`;

export const FilterLabel = styled.div`
  fontsize: 23px;
  margin-bottom: 0.5em;
  font-weight: bold;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MobileViewFilterWrapper = styled.div`
  margin: 0.5em 1em 0 1em;
  max-height: 300px;
  overflow-y: scroll;
`;

export const CardContainer = styled.div`
  padding: 0px 8px;
  border: 0px 1px 1px 1px solid black;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1em;
`;

export const FollowButton = styled.button<{ isFollowed: boolean }>`
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  background-color: ${props => props.isFollowed ? '#2ecc71' : '#3498db'};
  color: white;

  &:hover {
    background-color: ${props => props.isFollowed ? '#27ae60' : '#2980b9'};
  }

  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
`;

export const ErrorMessage = styled.p`
  color: #e74c3c;
  margin-top: 10px;
`;
