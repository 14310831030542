import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";

export const updateNewAddress = async (data: any) => {
  let uri = '/user/create-address';

  try {
    const res = await axios.post(uri, data, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const removeAddress = async (addressId: any) => {
  let uri = `/user/remove-address/${addressId}`;

  try {
    const res = await axios.delete(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    throw e;
  }
}

export const getAddresses = async () => {
  let uri = `/user/get-address`;

  try {
    const res = await axios.get(uri, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const setAddressAsDefault = async (address: any) => {
  let uri = `/user/set-address-default`;

  try {
    const res = await axios.post(uri, { setAsDefault: address.setAsDefault }, { headers: { "Authorization": `Bearer ${getAccessToken()}` } });
    return res.data;
  } catch (e) {
    throw e;
  }
}
