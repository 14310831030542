import React, { useState } from 'react';
import styled from 'styled-components';
import snack from 'components/wrapper/snack';

interface EmailSignupProps {
  onEmailSignup: (email: string) => void;
}

const EmailSignup: React.FC<EmailSignupProps> = ({ onEmailSignup }) => {
  const [email, setEmail] = useState<string>('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Basic email validation
    if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
      snack.error('Please enter a valid email address.');
      setEmail('');
      return;
    }

    // Call the parent component's callback to handle the signup
    onEmailSignup(email);
    setEmail('');
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <Info>
        <Heading>Join the club! Get 5% off.</Heading>
        <p>Sign up for our mailing list to receive exclusive promos, discounts, and offers!</p>
        <br />
      </Info>
      <Fields>
        <InputRow>
          <Label>Email:</Label>
          <Input
            type="email"
            value={email}
            name='email'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          />
        </InputRow>
        <Button type="submit">Sign Up</Button>
      </Fields>
    </FormWrapper>
  );
};

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  padding: 0 16px;
  width: fit-content;
  max-width: 350px;

  @media (max-width: 992px) {
    margin-top: 2rem;
    flex-direction: row;
    max-width: 100%;
    gap: 16px;
    align-items: center;
    padding: 16px;
  }

  @media (max-width: 840px) {
    flex-direction: column;
    align-items: flex-start;
    max-width: 350px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    max-width: 100%;
  }
`;

const Heading = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: #cfb53b;
  margin: 0;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  gap: 8px;
`;

const Input = styled.input`
  font: inherit;
  padding: 8px;
  border: 1px solid #000;
  border-radius: 4px;
  width: 100%;
  max-width: 250px;
`;

const Button = styled.button`
  font: inherit;
  padding: 8px;
  border: 1px solid #000;
  border-radius: 4px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const Label = styled.label`
  color: white;
  font-weight: 600;
  font-size: 0.9rem;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1; /* Set flex-grow to 1 to take up 1/3 of the space */
  @media (max-width: 992px) {
    flex-grow: 1; /* Adjust flex-grow value for responsive layout */
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 2; /* Set flex-grow to 2 to take up 2/3 of the space */
  @media (max-width: 992px) {
    flex-grow: 4; /* Adjust flex-grow value for responsive layout */
    min-width: 300px;
  }
`;

export default EmailSignup;
