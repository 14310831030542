import { call, put, takeLatest } from "redux-saga/effects";
import {
  getvalentineProductsStart,
  getvalentineProductsSuccess,
  getvalentineProductsFailure

} from 'redux/Home/valentine';
import { getValentineProducts } from "redux/services/Home/valentine";

function* valentineSagaWatcher() {
  yield takeLatest(
    [
      getvalentineProductsStart.type
    ],
    valentineWorker,
  );
}

function* valentineWorker(action: any): any {
  try {
    switch (action.type) {
      case getvalentineProductsStart.type: {
        const response: any = yield call(getValentineProducts, action.payload.category);
        yield put(getvalentineProductsSuccess({
          category: response.ResponseBody.category,
          result: response.ResponseBody.result,
        }));
      }
        break;

      default: console.error('invalid case');
        break;
    }

  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getvalentineProductsStart.type) {
      yield put(getvalentineProductsFailure(action.payload.category));
    } else if (action.type === getvalentineProductsStart.type) {
      console.log('error in getting valentine products');
    }
  }
}

export default valentineSagaWatcher;
