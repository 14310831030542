import { call, put, takeLatest } from "redux-saga/effects";
import {
  getKitchenProductsStart,
  getKitchenProductsSuccess,
  getKitchenProductsFailure

} from 'redux/Home/kitchen';
import { getKitchenProducts } from "redux/services/Home/kitchen";

function* kitchenSagaWatcher() {
  yield takeLatest(
    [
      getKitchenProductsStart.type
    ],
    kitchenWorker,
  );
}

function* kitchenWorker(action: any): any {
  try {
    switch (action.type) {
      case getKitchenProductsStart.type: {
        const response: any = yield call(getKitchenProducts, action.payload.category);
        yield put(getKitchenProductsSuccess({
          category: response.ResponseBody.category,
          result: response.ResponseBody.result,
        }));
      }
        break;

      default: console.error('invalid case');
        break;
    }

  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === getKitchenProductsStart.type) {
      yield put(getKitchenProductsFailure(action.payload.category));
    } else if (action.type === getKitchenProductsStart.type) {
      console.log('error in getting kitchen products');
    }
  }
}

export default kitchenSagaWatcher;
