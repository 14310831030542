import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean;
  msg?: string;
  updatedUser: any;
  emailOTPresponse: any;
  showEmailOTPComponent:  boolean;
  appVerifier?: any;
  showOtpScreen?:any
}

const initialState: State = {
  isLoading: true,
  error: false,
  updatedUser: {},
  emailOTPresponse: {},
  appVerifier: null,
  showOtpScreen: false,
  showEmailOTPComponent: false
};

export const accountSettingsSlice = createSlice({
  name: 'accountSettings',
  initialState,
  reducers: {
    accountSettingsUpdateStart: (state: State, action) => ({
      ...state,
      error: false,
      isLoading: true
    }),
    accountSettingsUpdateFail: (state: State, action) => ({
      ...state,
      isLoading: false,
      error: action.payload.error
    }),
    sendOTPtoMailStart: (state: State, action) => ({
      ...state,
      isLoading: true,
    }),
    sendOTPtoMailSuccess: (state: State) => ({
      ...state,
      isLoading: false,
      error: false,
      setShowEmailOtpComponent : true
    }),
    sendOTPtoMailFail: (state: State, action) => ({
      ...state,
      isLoading: false,
      error: action.payload.error,
      msg: action.payload.msg
    }),
    verifyOTPStart: (state: State, action) => ({
      ...state,
      isLoading : true,
      error: false
    }),
    sendOTPUsingFirebaseStart: (state: State, action) => ({
      ...state,
      isLoading: true
    }),
    sendOTPUsingFirebaseSuccess: (state: State, action) => ({
      ...state,
      isLoading: false
    }),
    sendOTPUsingFirebaseFail: (state:State, action) => ({
      ...state,
      isLoading: false,
      error: action.payload.error,
      msg: action.payload.message
    }),
    setLoading: (state: State, action) => ({
      ...state,
      isLoading: action.payload.loading
    }),
    setOtpScreen: (state: State, action) => ({
      ...state,
      showOtpScreen: action.payload.showOtpScreen
    }),
    showVerifyOTPStart: (state: State) => ({
      ...state,
      isLoading: true,
      showOtpScreen: true
    }),
    showVerifyOTPSuccess: (state: State, action) => ({
      ...state,
      isLoading: false,
      showOtpScreen: true,
      appVerifier: action.payload.appVerifier
    }),
    setShowEmailOtpComponent: (state: State, action) => ({
      ...state,
      showEmailOTPComponent : action.payload.showEmailOTPComponent
    })
  }
});

export const {
  accountSettingsUpdateFail,
  accountSettingsUpdateStart,
  sendOTPtoMailStart,
  verifyOTPStart,
  sendOTPUsingFirebaseStart,
  sendOTPUsingFirebaseSuccess,
  sendOTPUsingFirebaseFail,
  setLoading,
  showVerifyOTPStart,
  showVerifyOTPSuccess,
  setShowEmailOtpComponent,
  sendOTPtoMailFail,
  sendOTPtoMailSuccess,
  setOtpScreen
} = accountSettingsSlice.actions;
export default accountSettingsSlice.reducer;
