import { call, put, takeLatest } from "redux-saga/effects";
import {
  searchDataStart,
  searchDataSuccess,
  searchDataFailure,
  getProductsStartBasedOnFilters,
  getProduct,
  getProductSuccess,
  getProductFromSellLikeItem,
  listAnItemWithProduct,
} from "redux/product";
import {
  getFilterBasedProducts,
  getProducts,
  getProductByID,
} from "redux/services/product";
import { history } from "routes";
import snack from "components/wrapper/snack";
import { checkIsValidArray } from "utils";
import {
  fetchCategoriesStartForSellLikeItem,
  fetchChildCategoryStartForSellLikeItemSuccess,
} from "redux/categories";
import { getChildCategories } from "redux/services/categories";
const alchohal: any = { "499676": true, "3577": true };

function* productSagaWatcher() {
  yield takeLatest(
    [
      searchDataStart.type,
      getProduct.type,
      getProductsStartBasedOnFilters.type,
      getProductFromSellLikeItem.type,
    ],
    productWorker
  );
}

function* fetchChildCategories(productDetails: any): Generator<any, void, any> {
  const { parentCategoryIdList, parentCategoryNameList } = productDetails;
  yield put(
    fetchCategoriesStartForSellLikeItem({ intialLoadFromUpdateProduct: true })
  );

  for (const [index, categoryId] of parentCategoryIdList.entries()) {
    const categoryName = parentCategoryNameList[index];
    const data: any = yield call(getChildCategories, { parentId: categoryId });
    const useUps = !!alchohal[data?.ResponseBody?.parentId];
    yield put(
      fetchChildCategoryStartForSellLikeItemSuccess({
        basedOnCatergoryId: {
          [categoryId]: ((data || {})?.ResponseBody || {})?.childData || [],
        },
        categoryBreadCrum: `${categoryName}#${categoryId}`,
        categoryId: categoryId,
        categoryName: categoryName,
        useUps,
        intialLoadFromUpdateProduct: true,
      })
    );
  }
}
function* productWorker(action: any): any {
  try {
    switch (action.type) {
      case searchDataStart.type:
        {
          const response: any = yield call(getProducts, action.payload);
          yield put(searchDataSuccess({ products: response.ResponseBody }));
        }
        break;

      case getProductsStartBasedOnFilters.type:
        {
          const response: any = yield call(
            getFilterBasedProducts,
            action.payload
          );
          yield put(searchDataSuccess({ products: response.ResponseBody }));
        }
        break;
      case getProduct.type:
        {
          const response: any = yield call(getProductByID, action.payload.id);
          yield put(getProductSuccess({ product: response.ResponseBody }));
        }
        break;
      case getProductFromSellLikeItem.type:
        {
          const response: any = yield call(getProductByID, action.payload.id);
          if (response.ResponseBody) {
            const productDetails = response.ResponseBody;
            yield put(listAnItemWithProduct(productDetails));
            if (
              checkIsValidArray(productDetails.parentCategoryNameList) &&
              checkIsValidArray(productDetails.parentCategoryIdList) &&
              productDetails.parentCategoryIdList.length ===
                productDetails.parentCategoryNameList.length
            ) {
              yield fetchChildCategories(productDetails);
            }
          } else {
            history.push({ pathname: "/my-sales", search: "" });
            snack.error("Product doesn't exist");
          }
        }
        break;

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);

    if (action.type === searchDataStart.type) {
      yield put(searchDataFailure);
    }
  }
}

export default productSagaWatcher;
