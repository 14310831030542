import { createSlice } from "@reduxjs/toolkit";

interface State {
  sellerFilters: any;
  error: boolean;
  isLoading: boolean;
}

const initialState: State = {
  sellerFilters: { categories: [], brand: [] },
  error: false,
  isLoading: false,
};

export const sellerShopsFilterSlice = createSlice({
  name: "shops",
  initialState,
  reducers: {
    getSellerCategoriesStart: (state: State, action) => ({
      ...initialState,
      error: false,
      isLoading: true,
    }),
    getSellerCategoriesSuccess: (state: State, action) => {
      return {
        ...state,
        sellerFilters: action.payload,
        error: false,
        isLoading: false,
      };
    },
  },
});

export const { getSellerCategoriesStart, getSellerCategoriesSuccess } =
  sellerShopsFilterSlice.actions;

export default sellerShopsFilterSlice.reducer;
