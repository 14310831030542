import axios from "./axiosController";

const sendEventAndConversion = async (data: any) => {
  let url = "/algolia/track";
  try {
    const res = await axios.post(url, data);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export default sendEventAndConversion;
